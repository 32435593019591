import PlenaryRoomImage from "assets/images/rooms/PlenaryRoom.jpg";
import clubEspace1 from "assets/images/sponsors/background/clubEspace1.png";
import clubEspace2 from "assets/images/sponsors/background/clubEspace2.png";
import clubEspace3 from "assets/images/sponsors/background/clubEspace3.png";
import clubEspace4 from "assets/images/sponsors/background/clubEspace4.png";
import clubEspace5 from "assets/images/sponsors/background/clubEspace5.jpg";
import clubEspace6 from "assets/images/sponsors/background/clubEspace6.jpg";

export const backgroundImages: Record<string, string> = {
  clubPlenaryRoom: PlenaryRoomImage,
  clubEspace1: clubEspace1,
  clubEspace2: clubEspace2,
  clubEspace3: clubEspace3,
  clubEspace4: clubEspace4,
  clubEspace5: clubEspace5,
  clubEspace6: clubEspace6,
};
