import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Text from "components/Text";
import FormCompletionItem from "./FormCompletionItem";
import theme from "styles/theme";

interface FormsTabContentProps {
  sortedForms: any[];
  isAscending: boolean;
  handleSortToggle: () => void;
}

const FormsTabContent: React.FC<FormsTabContentProps> = ({
  sortedForms,
  isAscending,
  handleSortToggle,
}) => {
  return (
    <Box sx={{ mt: 2, overflow: "scroll" }}>
      {sortedForms.length > 0 ? (
        <Box
          sx={{
            maxHeight: 220,
            overflowY: "auto",
            borderRadius: 1,
            backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              padding: "0 1rem",
              backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
              borderRadius: "0.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                flex: "0.5",
                gap: "0.2rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flex: "0.45",
                }}
              >
                <Typography variant="caption" color={theme.COLORS.TEXT_PRIMARY}>
                  Nome
                </Typography>
                <IconButton onClick={handleSortToggle} size="small">
                  {isAscending ? (
                    <ArrowUpwardIcon fontSize="small" />
                  ) : (
                    <ArrowDownwardIcon fontSize="small" />
                  )}
                </IconButton>
              </Box>
            </Box>
            <Text size="small" flex={0.5} textAlign="center">
              Preenchimento
            </Text>
          </Box>
          <Box sx={{ mt: 1 }}>
            {sortedForms.map((form) => (
              <FormCompletionItem
                key={form.form_group_id}
                formName={form.form_group_name}
                completionPercentage={form.completion}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Text color="gray" textAlign="center">
          Nenhum formulário encontrato para o usuário.
        </Text>
      )}
    </Box>
  );
};

export default FormsTabContent;
