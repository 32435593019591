import React from "react";
import { Routes, Route, Outlet, Navigate, useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { PublicRoute } from "./PublicRoutes";
import { PrivateRoute } from "./PrivateRoutes";
import RouteTransition from "./RouteTransition";
import Layout from "components/Layout";
import Logout from "components/Logout";
import { Login } from "pages/Login";
import { Register } from "pages/Register";
import { ForgotPassword } from "pages/ForgotPassword";
import { Cockpit } from "pages/Cockpit";
import { CommunityProfile } from "pages/CommunityProfile";
import { IntelligenceNetworking } from "pages/IntelligenceNetworking";
import { ThematicGroups } from "pages/ThematicGroups";
import { UserProfile } from "pages/UserProfile";
import { DigitalGovernance } from "pages/DigitalGovernance";
import { FinancialManagement } from "pages/BusinessManagement/FinancialManagement";
import { CommercialManagement } from "pages/BusinessManagement/CommercialManagement";
import { DigitalMarketing } from "pages/BusinessManagement/DigitalMarketing";
import ErrorPage from "pages/Errors/Error";
import SelfAssessment from "pages/SelfAssessment";
import Challenges2025 from "pages/UserProfile/Challenges2025";
import FeedbackNetorkingExp from "pages/UserProfile/FeedbackNetworkingExp";
import InvitesPage from "pages/UserProfile/Invites";
import { AtivacaoPresencial } from "pages/NetworkingExperience/AtivacaoPresencial";
import { ActivationRounds } from "pages/Club/ActivationRounds";
import UserProfileMobile from "components/Layout/components/UserProfileMobile";
import PBIDash from "components/PBIDash";
import { useUser } from "global/UserContext";
import { NetworkingExperience } from "pages/NetworkingExperience";

export const AuthRoutes = () => {
  const { token, permissions } = useUser();
  const navigate = useNavigate();

  const hasAccess = (requiredPermission: string): boolean => {
    if (!permissions) return false;
    return permissions.includes(requiredPermission);
  };

  return (
    <Routes>
      <Route element={<RouteTransition />}>
        <Route
          path="/"
          element={
            <Navigate
              to={token ? ROUTES.ROOT + ROUTES.COCKPIT : ROUTES.AUTH.LOGIN}
              replace
            />
          }
        />

        <Route
          path={ROUTES.AUTH.LOGIN}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path={ROUTES.AUTH.REGISTER}
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path={ROUTES.AUTH.FORGOT_PASSWORD}
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path={ROUTES.AUTH.LOGOUT}
          element={
            <PublicRoute>
              <Logout />
            </PublicRoute>
          }
        />

        <Route
          path={ROUTES.ROOT}
          element={
            <PrivateRoute>
              <Layout>
                <Outlet />
              </Layout>
            </PrivateRoute>
          }
        >
          <Route index element={<Navigate to={ROUTES.COCKPIT} replace />} />

          <Route
            index
            path={ROUTES.COCKPIT}
            element={
              <PrivateRoute>
                <Cockpit />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.COMMUNITY_PROFILE}
            element={
              <PrivateRoute>
                <CommunityProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.INTELLIGENCE_NETWORKING}
            element={
              <PrivateRoute>
                <IntelligenceNetworking />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.THEMATIC_GROUPS}
            element={
              <PrivateRoute>
                <ThematicGroups />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CLUB_ACTIVATION_ROUNDS}
            element={
              <PrivateRoute>
                <ActivationRounds />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.DIGITAL_GOVERNANCE}
            element={
              <PrivateRoute>
                <DigitalGovernance />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.USER.PROFILE}
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.USER.PROFILE_MOBILE}
            element={
              <PrivateRoute>
                <UserProfileMobile />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.USER.PROFILE_MOBILE_SELF_ASSESSMENT}
            element={
              <PrivateRoute>
                <SelfAssessment />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.USER.PROFILE_MOBILE_CHALLENGES_AND_PROJECTS_2025}
            element={
              <PrivateRoute>
                <Challenges2025 />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.USER.PROFILE_FEEDBACK_NETWORK_EXPERIENCE}
            element={
              <PrivateRoute>
                <FeedbackNetorkingExp />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.USER.PROFILE_INVITES}
            element={
              <PrivateRoute>
                <InvitesPage />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.FINANCIAL_MANAGEMENT}
            element={
              <PrivateRoute>
                <FinancialManagement />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.COMMERCIAL_MANAGEMENT}
            element={
              <PrivateRoute>
                <CommercialManagement />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.DIGITAL_MARKETING}
            element={
              <PrivateRoute>
                <DigitalMarketing />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.COMMUNITY_GENERAL_PROFILE}
            element={
              <PrivateRoute>
                <PBIDash reportName="rmkr_general_networks" />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.COMMUNITY_BIO}
            element={
              <PrivateRoute>
                <PBIDash reportName="rmkr_bio_networks" />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.COMMUNITY_INDIVIDUAL_NETWORK}
            element={
              <PrivateRoute>
                <PBIDash reportName="rmkr_individual_network" />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.ATIVACAO_PRESENCIAL}
            element={
              <PrivateRoute>
                <NetworkingExperience />
              </PrivateRoute>
            }
          />

          <Route
            path="*"
            element={
              <ErrorPage
                title="Erro!"
                error="404"
                message="Página não encontrada."
                //buttonText="Voltar para o início"
                //onButtonClick={() => navigate(ROUTES.ROOT)}
              />
            }
          />
        </Route>

        <Route
          path="*"
          element={
            <ErrorPage
              title="Erro!"
              error="404"
              message="Página não encontrada."
              //buttonText="Voltar para o início"
              //onButtonClick={() => navigate(ROUTES.ROOT)}
            />
          }
        />
      </Route>
    </Routes>
  );
};
