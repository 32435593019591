import styled from "styled-components";


export const VideoContainer = styled.div`
   display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin-right: 12px;
  width: 100%;
  max-width: 900px; /* Optional: Limit the width of the video */
  aspect-ratio: 16 / 9; /* Ensures the proper aspect ratio */
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; /* Removes iframe border */
  }
`;
