import React from "react";
import { Tooltip } from "@mui/material";
import { ContainerText } from "./styles";
import useHasTextOverflow from "./hooks/useHasTextOverflow";

export enum TextSize {
  xSmall = "xSmall",
  small = "small",
  medium = "medium",
  large = "large",
  xLarge = "xLarge",
}

interface ITextProps {
  children: React.ReactNode;
  size?:
    | TextSize
    | "XXSmall"
    | "xSmall"
    | "small"
    | "medium"
    | "large"
    | "xLarge";
  bold?: boolean;
  color?: "white" | "black" | "gray" | "green" | "red" | "yellow" | "primary";
  secondary?: boolean;
  className?: string;
  truncate?: boolean;
  tooltip?: string;
  fontWeight?: number;
  textAlign?: "left" | "center" | "right" | "justify";
  flex?: number;
  underline?: boolean;
  onClick?: () => void;
}

const Text = ({
  children,
  size = TextSize.medium,
  bold = false,
  color = "white",
  secondary = false,
  className,
  truncate = false,
  tooltip,
  fontWeight,
  textAlign,
  flex,
  underline = false,
  onClick,
}: ITextProps) => {
  const { ref, hasOverflow } = useHasTextOverflow(children as string);

  const content = (
    <ContainerText
      ref={ref}
      size={size}
      bold={bold}
      color={color}
      secondary={secondary}
      className={className}
      truncate={truncate}
      fontWeight={fontWeight}
      textAlign={textAlign}
      flex={flex}
      underline={underline}
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "default" }}
    >
      {children}
    </ContainerText>
  );

  return truncate && hasOverflow ? (
    <Tooltip title={tooltip || children} placement="top">
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default Text;
