import React, { useEffect, useState } from "react";
import SponsorImage from "assets/images/rooms/sponsorship-algoricca.png";
import MeetingRoom from "pages/MeetingRoom";
import TabsCustom from "components/Tabs";
import { Image } from "antd";
import { useTab } from "global/TabContext";
import { useDiagnosisFilter } from "global/DiagnosisFilterContext";
import { useMeetingRooms } from "./hooks/useMeetingRooms";
import { backgroundImages } from "./utils/meetingImages";
import { generateLabels } from "./utils/meetingLabels";
import Spin from "components/Spin";
import * as S from "./styles";
import { YoutubeVideoEmbed } from "components/YoutubeVideoEmbed";
import { MeetingRoom as IMeetingRoom } from "pages/MeetingRoom/types/meetingTypes";
import { JitsiVideoEmbed } from "components/JitsiVideoEmbed";

export const ThematicGroups = () => {
  const { meetingRooms, loading } = useMeetingRooms();
  const [videoLoading, setVideoLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<string>("plenaryRoom");
  const { setActiveTabContext } = useTab();
  const { filters } = useDiagnosisFilter();
  const [filterKey, setFilterKey] = useState(Date.now());

  const filteredMeetingRooms = meetingRooms.slice(0, 7); // Mudar para (7, 14)

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  useEffect(() => {
    setFilterKey(Date.now());
  }, [filters]);

  if (loading) {
    return <Spin fullscreen />;
  }

  const changePoliticalTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  const backgroundImage =
    backgroundImages[activeTab] || "";

  const labels = generateLabels(filteredMeetingRooms);

  const imageSponsorship =
    activeTab === "technology" || activeTab === "clubEspace6";

  const renderMeetingRoom = (room: IMeetingRoom) => {
    //  Mudar para enviar o id da reunião para que isso seja lidado pelo serviço Meetings
    if (room.room_data?.vendor_code === "ZOOM") {
      return (
        <MeetingRoom
          key={`${filterKey}-${room.room_data.id}`}
          roomData={room.room_data}
          participantStatus={room.participation_status}
          isActivationRounds={false}
        />
      );
    } else if (room.room_data?.vendor_code === "YOUTUBE") {
      return (
        <YoutubeVideoEmbed
          videoId={room.room_data?.vendor_room_id}
          videoLoading={videoLoading}
          onLoad={() => setVideoLoading(false)}
        />
      );
    } else if (room.room_data?.vendor_code === "JITSI") {
      return (
        <JitsiVideoEmbed
          meetingRoom={room}
          videoLoading={videoLoading}
          onLoad={() => setVideoLoading(false)}
        />
      );
    }
  }

  return (
    <S.Wrapper backgroundImage={backgroundImage}>
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={filteredMeetingRooms.map((room, index) => (renderMeetingRoom(room)))}
          onChange={(key) => changePoliticalTab(key)}
        />
        {imageSponsorship && (
          <S.ImgContent>
            <Image
              width={350}
              src={SponsorImage}
              style={{ marginBottom: "1rem" }}
            />
          </S.ImgContent>
        )}
      </S.Content>
    </S.Wrapper>
  );
};
