import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Text from "components/Text";
import AgendaPage from "./AgendaPage";
import theme from "styles/theme";
import * as S from "./styles";

const Agenda: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <S.AgendaContainer>
      <Accordion
        expanded={isOpen}
        onChange={handleToggle}
        disableGutters
        sx={{
          backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                color: theme.COLORS.PRIMARY,
                fontSize: 36,
              }}
            />
          }
        >
          <Text size="large" bold>
            Agenda
          </Text>
        </AccordionSummary>
        <AccordionDetails>
          <AgendaPage />
        </AccordionDetails>
      </Accordion>
    </S.AgendaContainer>
  );
};

export default Agenda;
