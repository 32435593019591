import React, { useState } from "react";
import { Box, Grid, Paper, TextField } from "@mui/material";
import { useInvites } from "pages/UserProfile/useInvitesHook";
import { useUser } from "global/UserContext";
import { useIsMobile, useIsTablet } from "utils/mediaQueries";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import * as S from "../styles";

interface Form {
    id?: string;
    invitee_first_name: string;
    invitee_last_name: string;
    invitee_cell_phone: string;
    invitee_email: string;
    invite_status: string;
}

interface ModalInviteLinkProps {
    handleCloseModal: () => void;
    inviteLink: string
}

const ModalInviteLink: React.FC<ModalInviteLinkProps> = (props) => {
    const isMobile = useIsMobile();

    const handleClickCopyLink = async () => {
        try {

            // Check clipboard permissions
            const permissionStatus = await navigator.permissions.query({
                name: "clipboard-write" as PermissionName,
            });

            if (permissionStatus.state === "granted" || permissionStatus.state === "prompt") {
                // Attempt to copy using Clipboard API
                copyTextToClipboard(props.inviteLink);
            } else {
                // Fallback to manual copying if permission is denied
                copyTextManually(props.inviteLink);
            }
        } catch (err) {
            console.error("Error handling click copy link:", err);
            alert("An unexpected error occurred. Please try again.");
        }
    };


    const copyTextToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                alert("A URL do convite foi copiada!");
            })
            .catch((err) => {
                console.error("Failed to copy the URL using Clipboard API.", err);
                alert(`Falha ao copiar a URL. Por favor, copie-a manualmente. Erro: ${err}`);
                copyTextManually(text); // Fallback if Clipboard API fails
            });
    };

    const copyTextManually = (text: string) => {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
            const successful = document.execCommand("copy");
            if (successful) {
                alert("A URL do convite foi copiada!");
            } else {
                alert("Manual copy failed. Please try again.");
            }
        } catch (err) {
            console.error("Fallback copy failed: ", err);
            alert(`Falha ao copiar o texto da URL. Por favor, copie-a manualmente. Erro: ${err}`);
        } finally {
            document.body.removeChild(textarea);
        }
    };

    return (
        <GlobalModal
            open
            title={"LINK DE INDICAÇÃO"}
            confirmButtonText={"Copiar Link"}
            cancelButtonText="Fechar"
            onConfirm={handleClickCopyLink}
            onClose={props.handleCloseModal}
            onCancel={props.handleCloseModal}
            width={isMobile ? "95%" : "700px"}
        >
            <Paper variant="outlined" sx={{padding: "8px"}}>{props.inviteLink}</Paper>

        </GlobalModal>
    );
};

export default ModalInviteLink;
