import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Text from "components/Text";
import InternalButton from "components/InternalButton";
import TeamGroupItem from "./TeamGroupItem";
import { Group } from "pages/DigitalGovernance/GovernanceThematicGroups/types/groups";
import { OrganizationMember } from "pages/DigitalGovernance/GovernanceUsers/types/users";
import theme from "styles/theme";

interface GroupsTabContentProps {
  member: OrganizationMember;
  unavailableGroups: Group[];
  isAscending: boolean;
  onOpenGroupModal: () => void;
  onSortToggle: () => void;
  onClose: () => void;
  refreshGroups: () => void;
  onUpdate: () => void;
}

const GroupsTabContent: React.FC<GroupsTabContentProps> = ({
  member,
  unavailableGroups,
  isAscending,
  onOpenGroupModal,
  onSortToggle,
  onClose,
  refreshGroups,
  onUpdate,
}) => {
  const [sortedGroups, setSortedGroups] = useState<Group[]>([]);

  useEffect(() => {
    const sorted = [...unavailableGroups].sort((a, b) =>
      isAscending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
    );
    setSortedGroups(sorted);
  }, [unavailableGroups, isAscending]);

  return (
    <Box sx={{ mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          margin: "0.5rem 0",
        }}
      >
        <Text>Grupos Temáticos vinculados ({unavailableGroups.length})</Text>
        <InternalButton
          onClick={() => {
            onOpenGroupModal();
            onClose();
          }}
          size="large"
          background={theme.COLORS.PRIMARY}
          variant="outline"
          iconPosition="left"
          textColor={theme.COLORS.TEXT_PRIMARY}
          icon={<AddIcon sx={{ fontSize: "auto" }} />}
        >
          Vincular grupo temático existente
        </InternalButton>
      </Box>
      {sortedGroups.length > 0 ? (
        <Box
          sx={{
            maxHeight: 240,
            overflowY: "auto",
            borderRadius: 1,
            backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
            overflow: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              padding: "0 8px",
              backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
              borderRadius: "0.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flex: "0.45",
                padding: 0,
              }}
            >
              <Typography variant="caption" color={theme.COLORS.TEXT_PRIMARY}>
                Nome
              </Typography>
              <IconButton onClick={onSortToggle} size="small">
                {isAscending ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )}
              </IconButton>
            </Box>
            <Text size="small" flex={0.15} textAlign="center">
              Usuários ativos
            </Text>
            <Text size="small" flex={0.3} textAlign="center">
              Cargo
            </Text>
            <Text size="small" flex={0.1} textAlign="center">
              Ações
            </Text>
          </Box>
          <Box sx={{ mt: 1 }}>
            {sortedGroups.map((team) => (
              <TeamGroupItem
                key={team.uuid}
                member={member}
                teamName={team.name}
                activeUsers={team.members.length}
                position={
                  member.teams.find((t) => t.team_name === team.name)
                    ?.team_position || "Posição não disponível"
                }
                refreshGroups={refreshGroups}
                onUpdate={onUpdate}
                team={team}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Text color="gray" textAlign="center">
          Nenhum grupo encontrato para o usuário.
        </Text>
      )}
    </Box>
  );
};

export default GroupsTabContent;
