import { apiRainmakersV2 } from "services/apiService";

export const acceptInvitation = async (eventId: string, userId: string) => {
  const payload = [
    {
      user_uuid: userId,
      role: "PARTICIPANT",
      is_blocked: false,
    },
  ];

  try {
    await apiRainmakersV2.post(`/events/v1/participants/${eventId}`, payload);
    console.log("Inscrição realizada com sucesso!");
  } catch (error) {
    console.error("Erro ao realizar inscrição:", error);
  }
};
