import React, { useState, useRef, useEffect, useCallback } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Radio } from "@mui/material";
import Button from "components/Button";
import useImages from "./utils/useImages";
import theme from "styles/theme";
import * as S from "./styles";

const ImageCarousel: React.FC = () => {
  const { images } = useImages();
  const carouselRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const cardsPerPage = 3;

  const totalPages = Math.ceil(images.length / cardsPerPage);

  const moveToPage = (page: number) => {
    if (carouselRef.current) {
      const cardWidth =
        (carouselRef.current.firstChild as HTMLElement)?.clientWidth || 0;
      const gap = 10;
      const scrollLeft = page * (cardsPerPage * (cardWidth + gap));
      carouselRef.current.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
      setCurrentPage(page);
    }
  };

  const moveToNext = useCallback(() => {
    const nextPage = (currentPage + 1) % totalPages;
    moveToPage(nextPage);
  }, [currentPage, totalPages]);

  const moveToPrev = useCallback(() => {
    const prevPage = (currentPage - 1 + totalPages) % totalPages;
    moveToPage(prevPage);
  }, [currentPage, totalPages]);

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        moveToNext();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [currentPage, isPaused, moveToNext]);

  return (
    <S.CarouselWrapper
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <S.CarouselContainer ref={carouselRef}>
        {images.map((image, index) => (
          <S.ImageCard key={image.id} active={currentPage === index}>
            <img src={image.src} alt={image.title} draggable={false} />
            {image.link && (
              <S.StyledButton>
                <Button
                  onClick={() => window.open(image.link, "_blank")}
                  style={{
                    color: theme.COLORS.BLACK,
                    borderRadius: "0.5rem",
                    height: "24px",
                    opacity: 0.5,
                  }}
                  onMouseEnter={(e: any) =>
                    (e.currentTarget.style.opacity = "1")
                  }
                  onMouseLeave={(e: any) =>
                    (e.currentTarget.style.opacity = "0.3")
                  }
                >
                  Saiba Mais
                </Button>
              </S.StyledButton>
            )}
          </S.ImageCard>
        ))}
      </S.CarouselContainer>

      <S.IndicatorWrapper>
        <S.ArrowButton onClick={moveToPrev}>
          <ArrowBackIosIcon fontSize="small" />
        </S.ArrowButton>

        {Array.from({ length: totalPages }, (_, index) => (
          <Radio
            key={index}
            checked={currentPage === index}
            onChange={() => moveToPage(index)}
            size="small"
            sx={{
              width: "0.25rem",
              height: "0.25rem",
              color: theme.COLORS.GRAY_10,
              "&.Mui-checked": {
                color: theme.COLORS.PRIMARY,
              },
            }}
          />
        ))}

        <S.ArrowButton onClick={moveToNext}>
          <ArrowForwardIosIcon fontSize="small" />
        </S.ArrowButton>
      </S.IndicatorWrapper>
    </S.CarouselWrapper>
  );
};

export default ImageCarousel;
