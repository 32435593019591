import React, { useState } from "react";
import { Box, Card, CardContent, CardActions } from "@mui/material";
import theme from "styles/theme";
import Text from "components/Text";
import Button from "components/Button";
import { EventCardProps } from "../types/agendaTypes";
import { useUser } from "global/UserContext";
import { acceptInvitation } from "../hooks/acceptInvitation";
import EventInfo from "./EventInfo";
import ConfirmationModal from "./ConfirmationModal";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import MeetingRoom from "pages/MeetingRoom";
import { RoomData } from "pages/MeetingRoom/types/meetingTypes";
import { useEvents } from "../hooks/useEvents";
import { useIsMobile, useIsTablet, useIsDesktop } from "utils/mediaQueries";

const EventCard: React.FC<EventCardProps> = ({ event }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const { refreshEvents } = useEvents();
  const { user } = useUser();
  const userId = user?.id;
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);
  const [isMeetingModalOpen, setMeetingModalOpen] = useState(false);
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleConfirm = async () => {
    if (!userId) return;
    await acceptInvitation(event.id, userId);
    setModalOpen(false);
    console.log("Inscrição realizada com sucesso!");
    setConfirmationModalOpen(false);
    await refreshEvents();
  };

  const isUserRegistered = !!(
    event.participants?.participants &&
    event.participants.participants.some(
      (participant) => participant.user_uuid === userId,
    )
  );

  const currentDate = new Date();
  const eventDate = new Date(event.date + "T" + event.time);

  const getButtonLabel = () => {
    if (isUserRegistered) {
      return event.location?.startsWith("Sala")
        ? "Acessar Sala"
        : "Ver Detalhes";
    }
    return "Responder convite";
  };

  const isButtonDisabled = () => {
    if (
      isUserRegistered &&
      event.location?.startsWith("Sala") &&
      event.isOngoing
    ) {
      return !event.isOngoing;
    } else if (!isUserRegistered) {
      return false;
    }
    return true;
  };

  const handleButtonClick = () => {
    if (isUserRegistered && event.room) {
      if (event.isOngoing) {
        setMeetingModalOpen(true);
      } else {
        console.log("Reunião ainda não começou.");
      }
    } else if (isUserRegistered) {
      setDetailsModalOpen(true);
    } else {
      setConfirmationModalOpen(true);
    }
  };

  const handleMeetingConfirm = async () => {
    setMeetingModalOpen(false);

    if (!event?.room?.room_data || !event?.room?.participation_status) {
      setErrorMessage(
        "Sala de reunião inválida ou participação não autorizada.",
      );
      setErrorModalOpen(true);
      return;
    }

    try {
      const { room_data } = event.room;

      if (!room_data.id || !room_data.vendor_room_id || !room_data.room_pwd) {
        setErrorMessage("Dados incompletos da sala de reunião.");
        setErrorModalOpen(true);
        return;
      }

      const iframeUrl = `${process.env.REACT_APP_URL_MEETING}/meetings/room?user=${userId}&meeting=${room_data.vendor_room_id}&pwd=${room_data.room_pwd}`;

      console.log("URL da sala de reunião:", iframeUrl);

      const openMeetingWindow = () => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const left = screenWidth / 2 - 500;
        const top = screenHeight / 2 - 225;

        const windowFeatures = `toolbar=no, menubar=no, width=1000, height=450, top=${top}, left=${left}`;

        window.open(iframeUrl, "_blank", windowFeatures);
      };

      if (iframeUrl) {
        openMeetingWindow();
      } else {
        setErrorMessage("Erro ao gerar o URL da reunião.");
        setErrorModalOpen(true);
      }
    } catch (error) {
      console.error("Erro ao abrir a reunião:", error);
      setErrorMessage("Ocorreu um erro inesperado ao abrir a reunião.");
      setErrorModalOpen(true);
    } finally {
      await refreshEvents();
    }
  };

  return (
    <>
      <Card
        sx={{
          background: theme.COLORS.BACKGROUND_PRIMARY,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: 2,
          width: "20rem",
          height: "13rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CardContent>
          <Text size="small" bold truncate tooltip={event.title}>
            {event.title}
          </Text>

          <Text size="small" truncate tooltip={event.location}>
            {event.location}
          </Text>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "0.5rem",
            }}
          >
            <Text
              size="xLarge"
              color="primary"
              bold
              truncate
              tooltip={event.time}
            >
              {event.isOngoing
                ? `Acontecendo`
                : `${event.date} - ${event.time}`}
            </Text>
            <EventInfo
              totalParticipants={event.participants.total}
              duration={event.duration}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            style={{
              height: "2.25rem",
              marginLeft: "20%",
              marginRight: "20%",
              marginBottom: "0.25rem",
            }}
            disabled={isButtonDisabled()}
            onClick={handleButtonClick}
          >
            {getButtonLabel()}
          </Button>
        </CardActions>
      </Card>

      <ConfirmationModal
        open={isConfirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={handleConfirm}
        event={{
          title: event.title,
          location: event.location || "Local não informado",
          date: event.date,
          time: event.time,
        }}
      />

      <GlobalModal
        open={isDetailsModalOpen}
        title="DETALHES DO EVENTO"
        onClose={() => setDetailsModalOpen(false)}
        width="35rem"
        height="25rem"
      >
        <Text size="small">{event.title}</Text>
        <Text size="small">
          <strong>Local:</strong> {event.location || "Local não informado"}
        </Text>
        <Text size="small">
          <strong>Data:</strong> {event.date}
        </Text>
        <Text size="small">
          <strong>Horário:</strong> {event.time}
        </Text>
        <Text size="small">
          <strong>Duração:</strong> {event.duration}
        </Text>
        <Text size="small">
          <strong>Total de Participantes:</strong> {event.participants.total}
        </Text>
      </GlobalModal>

      <GlobalModal
        open={isMeetingModalOpen}
        title="CONFIRMAÇÃO"
        onConfirm={handleMeetingConfirm}
        onCancel={() => setMeetingModalOpen(false)}
        onClose={() => setMeetingModalOpen(false)}
        confirmButtonText="Entrar"
        cancelButtonText="Cancelar"
        width="30rem"
        height="15rem"
      >
        <Text size="small">Você está prestes a entrar na reunião:</Text>
        <Box sx={{ marginTop: "1rem" }}>
          <Text size="medium" bold>
            {event.title}
          </Text>
          <Text size="small" color="primary">
            {event.location}
          </Text>
          <Text size="small" color="primary">
            Data: {event.date} - {event.time}
          </Text>
        </Box>
      </GlobalModal>

      <GlobalModal
        open={isErrorModalOpen}
        title="Erro"
        onClose={() => setErrorModalOpen(false)}
        width="30rem"
        height="15rem"
        confirmButtonText="Fechar"
        onConfirm={() => setErrorModalOpen(false)}
      >
        <Text size="medium" color="red">
          {errorMessage}
        </Text>
      </GlobalModal>
    </>
  );
};

export default EventCard;
