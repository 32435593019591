/**
 * Formata um número de telefone no padrão "(DDD) XXXXX-XXXX".
 * @param phoneNumber Número de telefone em formato internacional, ex: +5511999999999.
 * @returns Número formatado no padrão "(11) 9.9999-9999".
 */
export const formatPhoneNumber = (phoneNumber: string): string => {
  const match = phoneNumber.match(/^\+55(\d{2})(\d{1})(\d{4})(\d{4})$/);
  if (!match) {
    return phoneNumber;
  }
  const [, ddd, firstPart, secondPart, tertiaryPart] = match;
  return `(${ddd}) ${firstPart}.${secondPart}-${tertiaryPart}`;
};
