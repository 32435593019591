import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
  Box,
  Zoom,
} from "@mui/material";
import Button from "components/Button";
import theme from "styles/theme";

interface GlobalModalProps {
  open: boolean;
  title: string;
  description?: string;
  onClose: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmDisabled?: boolean;
  cancelDisabled?: boolean;
  children?: React.ReactNode;
  width?: string;
  height?: string;
}

const GlobalModal: React.FC<GlobalModalProps> = ({
  open,
  title,
  description,
  onClose,
  onConfirm,
  onCancel,
  confirmButtonText = "Confirmar",
  cancelButtonText = "Cancelar",
  confirmDisabled = false,
  cancelDisabled = false,
  children,
  width = "30rem",
  height = "20rem",
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => {}}
      disableEscapeKeyDown
      TransitionComponent={Zoom}
      keepMounted
      PaperProps={{
        sx: {
          background: theme.COLORS.BACKGROUND_SECONDARY,
          borderRadius: "8px",
          minWidth: width,
          width: width,
          maxWidth: width,
          minHeight: height,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          color: theme.COLORS.TEXT_PRIMARY,
          fontFamily: "Roboto",
          fontSize: "1.5rem",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "1.5rem",
          letterSpacing: "0.15rem",
          textTransform: "uppercase",
        }}
      >
        {title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {description && (
          <Typography
            sx={{
              color: theme.COLORS.TEXT_PRIMARY,
              textAlign: "start",
              mb: 2,
              whiteSpace: "pre-line",
            }}
          >
            {description &&
              description.split(".").map(
                (sentence, index) =>
                  sentence.trim() && (
                    <React.Fragment key={index}>
                      {sentence}
                      <br />
                    </React.Fragment>
                  ),
              )}{" "}
          </Typography>
        )}
        {children && <Box>{children}</Box>}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", padding: "2rem" }}>
        {onCancel && (
          <Button
            variant="outline"
            onClick={onCancel}
            fullWidth
            disabled={cancelDisabled}
          >
            {cancelButtonText}
          </Button>
        )}
        <Button
          variant="primary"
          onClick={onConfirm || onClose}
          fullWidth
          style={{ marginLeft: onCancel ? "1rem" : 0 }}
          disabled={confirmDisabled}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GlobalModal;
