import styled from "styled-components";
import theme from "styles/theme";

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1350px;
  margin: auto;
`;

export const CarouselContainer = styled.div`
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  gap: 10px;
`;

export const ImageCard = styled.div<{ active: boolean }>`
  flex: 0 0 240px;
  height: 280px;
  background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: ${({ active }) => (active ? 1 : 1)};
    transition: opacity 0.3s ease-in-out;
  }
`;

export const StyledButton = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 128px;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  button {
    width: 100%;
    background-color: ${theme.COLORS.PRIMARY};
    color: ${theme.COLORS.GRAY_10};
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
`;

export const IndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  gap: 5px;
`;

export const ArrowButton = styled.button`
  background-color: ${theme.COLORS.BACKGROUND_PRIMARY};
  color: ${theme.COLORS.PRIMARY};
  border: none;
  padding: 0;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.5rem;
  height: 0.5rem;

  font-size: 0.5rem;

  &:hover {
    opacity: 1;
  }
`;
