import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import EventCard from "./EventCard";
import { EventCarouselProps } from "../types/agendaTypes";
import { useIsMobile, useIsTablet } from "utils/mediaQueries";

const EventCarousel: React.FC<EventCarouselProps> = ({ events }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDragging(true);
    document.body.style.userSelect = "none";
    const pageX = "touches" in e ? e.touches[0].pageX : e.pageX;
    setStartX(pageX - (carouselRef.current?.offsetLeft || 0));
    setScrollLeft(carouselRef.current?.scrollLeft || 0);
  };

  const handleDragMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    const pageX = "touches" in e ? e.touches[0].pageX : e.pageX;
    const x = pageX - (carouselRef.current?.offsetLeft || 0);
    const walk = (x - startX) * 2;
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    document.body.style.userSelect = "auto";
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0,
        marginLeft: isMobile || isTablet ? 0 : "-5rem",
        marginRight: isMobile || isTablet ? 0 : "-5rem",
        overflowX: "hidden",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      <Box
        ref={carouselRef}
        sx={{
          display: "flex",
          gap: 1,
          overflowX: "auto",
          padding:
            isMobile || isTablet
              ? "0.2rem 0rem 1rem 0rem"
              : "0.2rem 5rem 1rem 5rem",
          scrollBehavior: "smooth",
          cursor: isDragging ? "grabbing" : "grab",
          "&::-webkit-scrollbar": { display: "none" },
        }}
        onMouseDown={handleDragStart}
        onMouseMove={handleDragMove}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd}
        onTouchStart={handleDragStart}
        onTouchMove={handleDragMove}
        onTouchEnd={handleDragEnd}
      >
        {events.map((event) => (
          <Box
            key={event.id}
            sx={{
              flex: "0 0 auto",
              marginRight: "0.5rem",
            }}
          >
            <EventCard event={event} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default EventCarousel;
