import React, { useEffect, useState } from "react";
import { TextField, Box, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Text from "components/Text";
import Spin from "components/Spin";
import InternalButton from "components/InternalButton";
import CreateGroupModal from "./components/CreateGroupModal";
import GroupList from "./components/GroupList";
import { apiOrchestrator } from "services/apiService";
import theme from "styles/theme";
import * as S from "./styles";

const GovernanceThematicGroups: React.FC = () => {
  const [groups, setGroups] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchGroups = async () => {
    setLoading(true);
    try {
      const response = await apiOrchestrator.get("/team/all");
      setGroups(response.data);
    } catch (error) {
      console.error("Erro ao buscar grupos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredGroups = groups.filter((group) =>
    group.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        my: 0,
        padding: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "0.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "0.4",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Text size="large">Grupos Temáticos ({filteredGroups.length})</Text>
          </Box>
          <TextField
            variant="outlined"
            placeholder="Buscar por Grupo Temático"
            value={searchTerm}
            onChange={handleSearchChange}
            size="small"
            fullWidth
            sx={S.SearchService}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "0.75rem",
            flex: "0.5",
            justifyContent: "flex-end",
          }}
        >
          <InternalButton
            size="large"
            background={theme.COLORS.PRIMARY}
            textColor={theme.COLORS.BLACK}
            variant="primary"
            iconPosition="left"
            icon={<AddIcon sx={{ fontSize: "1.2rem" }} />}
            onClick={() => setOpenModal(true)}
          >
            Criar novo Grupo Temático
          </InternalButton>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <Spin />
          </Box>
        ) : filteredGroups.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <Text color="gray">
              {searchTerm
                ? "Nenhum grupo encontrado com o termo buscado."
                : "Nenhum grupo temático cadastrado ainda."}
            </Text>
          </Box>
        ) : (
          <GroupList
            groups={filteredGroups}
            searchTerm={searchTerm}
            onUpdate={fetchGroups}
          />
        )}
      </Box>

      <CreateGroupModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onGroupCreated={(groupId) => {}}
        onUpdate={fetchGroups}
      />
    </Box>
  );
};

export default GovernanceThematicGroups;
