import React from "react";
import { Box } from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Text from "components/Text";
import theme from "styles/theme";

interface EventInfoProps {
  totalParticipants: number;
  duration: string;
}

const EventInfo: React.FC<EventInfoProps> = ({
  totalParticipants,
  duration,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-evenly",
        padding: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <PeopleAltIcon fontSize="small" htmlColor={theme.COLORS.PRIMARY} />
        <Text size="small">{totalParticipants} inscritos</Text>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <AccessAlarmIcon fontSize="small" htmlColor={theme.COLORS.PRIMARY} />
        <Text size="small">{duration}</Text>
      </Box>
    </Box>
  );
};

export default EventInfo;
