import React, { useState } from "react";
import { Typography, IconButton, Box } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PaginationComponent from "components/Pagination/Pagination";
import Events from "./EventsCard/Events";
import { Event } from "../types/events";
import theme from "styles/theme";

interface EventListProps {
  events: Event[];
  searchTerm: string;
  onUpdate: () => void;
  loading: boolean;
}

const ITEMS_PER_PAGE = 10;

export const EventList: React.FC<EventListProps> = ({
  events = [],
  searchTerm,
  onUpdate,
  loading,
}) => {
  const [sortBy, setSortBy] = useState<"name">("name");
  const [isAscending, setIsAscending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSortToggle = (column: "name") => {
    if (sortBy === column) {
      setIsAscending(!isAscending);
    } else {
      setSortBy(column);
      setIsAscending(true);
    }
  };

  const filteredAndSortedEvents = events
    .map((event: any) => ({
      ...event,
      participantsTotal: event.participants?.total || 0,
      date: event.schedule?.slots?.[0]?.init_datetime || "",
    }))
    .filter((event) =>
      event.name?.toLowerCase().includes(searchTerm.toLowerCase()),
    )
    .sort((a, b) => {
      if (!a[sortBy] || !b[sortBy]) return 0;
      return isAscending
        ? String(a[sortBy]).localeCompare(String(b[sortBy]))
        : String(b[sortBy]).localeCompare(String(a[sortBy]));
    });

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedEvents = filteredAndSortedEvents.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE,
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 0.5rem",
          backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
          color: theme.COLORS.TEXT_PRIMARY,
          borderRadius: "0.25rem",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flex: "0.45" }}>
          <Typography variant="caption">Nome</Typography>
          <IconButton onClick={() => handleSortToggle("name")} size="small">
            {isAscending ? (
              <ArrowUpwardIcon fontSize="small" />
            ) : (
              <ArrowDownwardIcon fontSize="small" />
            )}
          </IconButton>
        </Box>
        <Typography
          variant="caption"
          sx={{ flex: "0.15", textAlign: "center" }}
        >
          Status
        </Typography>
        <Typography
          variant="caption"
          sx={{ flex: "0.15", textAlign: "center" }}
        >
          Data
        </Typography>
        <Typography
          variant="caption"
          sx={{ flex: "0.15", textAlign: "center" }}
        >
          Participantes
        </Typography>
        <Typography
          variant="caption"
          sx={{ flex: "0.10", textAlign: "center" }}
        >
          Ações
        </Typography>
      </Box>
      <Box sx={{ width: "100%", marginTop: 1 }}>
        {loading ? (
          <Typography>Carregando eventos...</Typography>
        ) : paginatedEvents.length > 0 ? (
          paginatedEvents.map((event) => (
            <Events key={event.id} event={event} onUpdate={onUpdate} />
          ))
        ) : (
          <Typography>Nenhum evento encontrado.</Typography>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
        }}
      >
        <PaginationComponent
          totalPages={Math.ceil(
            filteredAndSortedEvents.length / ITEMS_PER_PAGE,
          )}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          siblingCount={1}
          boundaryCount={1}
          color="primary"
          size="medium"
          customColor={theme.COLORS.BACKGROUND_SECONDARY}
        />
      </Box>
    </>
  );
};

export default EventList;
