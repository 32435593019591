import styled from "styled-components";
import theme from "styles/theme";



export const Wrapper = styled.div`
&& .MuiPaper-root {
    padding: 8px;
    width: 100%;
  }
`

export const Container = styled.div`
  width: 100%;
  max-width: 500px;
  margin: auto;
  place-content: center;
  display: block;
  // background-color: red;
`

export const SearchService = {
  height: "1.5rem",
  "& .MuiInputBase-root": {
    minHeight: "1.5rem",
    padding: "2px",
  },
  "& .MuiInputBase-input": {
    height: "1.5rem",
    padding: "0 8px",
    fontSize: "0.8rem",
  },
};



export const ProfilePictureWrapper = styled.div`
  position: relative;
  text-align: center;
`;


export const PreviewImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;


export const ProfilePicture = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
`;


export const MiniPictureContainer = styled.div`
  width: 50px;

  img {
    width: 50px;
  }
`