import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Box,
  Avatar,
  Skeleton,
} from "@mui/material";
import Text from "components/Text";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ViewMemberModal from "../components/ViewMemberModal";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import { useToast } from "hooks/useToast";
import {
  User,
  GroupRole,
  Profile,
  IndividualPlan,
  Team,
  InvitedBy,
  FormCompletion,
  Forms,
  OrganizationMember,
} from "../types/users";
import theme from "styles/theme";
import useUserImage from "hooks/useUserImage";

const MemberCard: React.FC<{
  member: OrganizationMember;
  onUpdate: () => void;
}> = ({ member, onUpdate }) => {
  const { toastSuccess, toastError } = useToast();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isConfirmUnlinkOpen, setConfirmUnlinkOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);
  const [editEventId, setEditEventId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    userImage,
    loadingImage,
    error: errorImage,
  } = useUserImage(member.user?.id);

  const handleViewMember = () => {
    setIsEditMode(true);
    setEditEventId(member.user.id);
    setIsModalOpen(true);
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUnlinkMember = () => {
    setConfirmUnlinkOpen(true);
    handleMenuClose();
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "0.5rem",
          minHeight: "3.5rem",
          borderRadius: "0.75rem",
          background: theme.COLORS.BACKGROUND_SECONDARY,
          color: theme.COLORS.TEXT_SECONDARY,
          marginBottom: 1,
        }}
      >
        <Box
          sx={{
            "& .MuiAccordionSummary-expandIconWrapper": {
              pointerEvents: "auto",
            },
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", flex: "0.3" }}>
            {loadingImage ? (
              <Skeleton
                variant="circular"
                width={"2.5rem"}
                height={"2.5rem"}
                sx={{ marginRight: "0.5rem" }}
              />
            ) : (
              <Avatar
                src={userImage || undefined}
                alt={`${member.user?.first_name} ${member.user?.last_name}`}
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                  marginRight: "0.5rem",
                }}
              />
            )}
            <Text bold textAlign="left">
              {`${member.user?.first_name || ""} ${member.user?.last_name || ""}` ||
                "Nome não disponível"}
            </Text>
          </Box>

          <Text textAlign="center" flex={0.15} fontWeight={300}>
            {member.group_role?.name || "Perfil não disponível"}
          </Text>

          <Text textAlign="center" flex={0.15} fontWeight={300}>
            {member.individual_plan?.name || "Plano Gratuito"}
          </Text>

          <Text textAlign="center" flex={0.3} fontWeight={300}>
            {member.forms?.overall_completion !== undefined
              ? `${Math.round(member.forms.overall_completion)}%`
              : "0%"}
          </Text>

          {/* <Text textAlign="center" flex={0.15} fontWeight={300}>
            Data não registrada
          </Text> */}

          <Box
            sx={{
              display: "flex",
              flex: "0.10",
              justifyContent: "center",
            }}
          >
            <IconButton onClick={handleMenuOpen}>
              <MoreHorizIcon
                sx={{
                  color: theme.COLORS.PRIMARY,
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          "& .MuiPaper-root": {
            background: theme.COLORS.BACKGROUND_PRIMARY,
            color: theme.COLORS.TEXT_PRIMARY,
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "2px",
          },
          "& .MuiMenuItem-root": {
            fontSize: "0.75rem",
            padding: "2px 4px",
            "&:hover": {
              backgroundColor: theme.COLORS.PRIMARY,
              color: theme.COLORS.BLACK,
              borderRadius: "4px",
            },
          },
        }}
      >
        <MenuItem onClick={handleViewMember}>Visualizar Membro</MenuItem>
        {/*         <MenuItem onClick={handleOpenUnlinkMember}>Desvincular Membro</MenuItem>
         */}{" "}
      </Menu>

      <ViewMemberModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        member={member}
        onUpdate={onUpdate}
      />

      <GlobalModal
        open={isSuccessOpen}
        title="CONFIRMAÇÃO"
        description="Evento cancelado com sucesso!"
        onClose={() => setIsSuccessOpen(false)}
        confirmButtonText="Fechar"
      />
    </>
  );
};

export default MemberCard;
