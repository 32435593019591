import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Zoom,
} from "@mui/material";
import Text from "components/Text";
import Button from "components/Button";
import TabsCustom from "components/Tabs";
import GroupSelect from "./components/GroupSelect";
import FormsTabContent from "./components/FormsTabContent";
import GroupsTabContent from "./components/GroupTabContent";
import HeaderInfoMember from "./components/HeaderInfoMember";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import { apiOrchestrator } from "services/apiService";
import useUserImage from "hooks/useUserImage";
import { useToast } from "hooks/useToast";
import { useMemberData } from "./hooks/useMemberData";
import { useAvailableGroups } from "./hooks/useAvailableGroups";
import {
  User,
  GroupRole,
  Profile,
  IndividualPlan,
  Team,
  InvitedBy,
  FormCompletion,
  Forms,
  OrganizationMember,
} from "../../types/users";
import theme from "styles/theme";

interface IViewMemberModal {
  open: boolean;
  onClose: () => void;
  member: OrganizationMember;
  onUpdate: () => void;
}

const ViewMemberModal: React.FC<IViewMemberModal> = ({
  open,
  onClose,
  member,
  onUpdate,
}) => {
  const [currentMember, setCurrentMember] = useState(member);
  const {
    data: memberData,
    loading: loadingMemberData,
    error: errorMemberData,
  } = useMemberData(currentMember.user?.id);

  const {
    userImage: imageOfWhoInvitedHim,
    loadingImage: loadingImageOfWhoInvitedHim,
    error: errorImageOfWhoInvitedHim,
  } = useUserImage(currentMember.invited_by?.user_uuid);

  const {
    userImage: imageOfMember,
    loadingImage: loadingImageOfMember,
    error: errorImageOfMember,
  } = useUserImage(currentMember.user.id);

  const {
    availableGroups,
    unavailableGroups,
    loading: loadingAvailableGroups,
    refreshGroups,
  } = useAvailableGroups(currentMember.user?.id);

  const [activeTab, setActiveTab] = useState("tab_groups");
  const [selectedGroupUuid, setSelectedGroupUuid] = useState<string | null>(
    null,
  );

  const handleUpdateMember = async (newMemberId: string) => {
    try {
      const response = await apiOrchestrator.get(
        `/users-person/${newMemberId}`,
      );
      setCurrentMember((prevMember) => ({
        ...prevMember,
        user: { ...prevMember.user, id: newMemberId },
        ...response.data,
      }));
      toastSuccess("Membro atualizado com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar membro:", error);
      toastError("Erro ao atualizar membro.");
    }
  };

  const handleGroupSelect = (groupUuid: string | null) => {
    setSelectedGroupUuid(groupUuid);
  };

  const handleLinkGroup = async () => {
    if (selectedGroupUuid) {
      try {
        await apiOrchestrator.post(`/user_to_team/${selectedGroupUuid}`, [
          { user_uuid: currentMember.user?.id, team_position: "Membro" },
        ]);
        toastSuccess("Usuário vinculado com sucesso!");
        await refreshGroups();
        //onUpdate();
        setSelectedGroupUuid(null);
        onClose();
      } catch (err: any) {
        toastError(err.message || "Erro ao vincular usuário ao grupo.");
      }
    }
  };

  const handleRefreshGroups = async () => {
    await refreshGroups();
  };

  const { toastError, toastSuccess } = useToast();

  const [isOpenGroupModal, setOpenGroupModal] = useState(false);

  const [isAscending, setIsAscending] = useState(true);
  const [sortedForms, setSortedForms] = useState(
    currentMember.forms?.form_completions || [],
  );

  useEffect(() => {
    const sorted = [...sortedForms].sort((a, b) => {
      if (isAscending) {
        return a.form_group_name.localeCompare(b.form_group_name);
      } else {
        return b.form_group_name.localeCompare(a.form_group_name);
      }
    });
    setSortedForms(sorted);
  }, [isAscending, currentMember.forms?.form_completions]);

  const handleSortToggle = () => {
    setIsAscending(!isAscending);
  };

  const labels = [
    { label: "Grupos Temáticos", key: "tab_groups" },
    { label: "Formulários", key: "tab_forms" },
  ];

  const content = [
    <GroupsTabContent
      key="groups-tab"
      member={currentMember}
      unavailableGroups={unavailableGroups}
      isAscending={isAscending}
      onOpenGroupModal={() => setOpenGroupModal(true)}
      onSortToggle={handleSortToggle}
      onClose={onClose}
      refreshGroups={handleRefreshGroups}
      onUpdate={onUpdate}
    />,
    <FormsTabContent
      key="forms-tab"
      sortedForms={sortedForms}
      isAscending={isAscending}
      handleSortToggle={handleSortToggle}
    />,
  ];

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose();
          }
        }}
        fullWidth
        maxWidth="sm"
        disableEscapeKeyDown
        TransitionComponent={Zoom}
        keepMounted
        PaperProps={{
          sx: {
            background: theme.COLORS.BACKGROUND_SECONDARY,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            height: "40rem",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: theme.COLORS.TEXT_PRIMARY,
            fontSize: 18,
          }}
        >
          MEMBRO
        </DialogTitle>

        <Divider />

        <DialogContent>
          <HeaderInfoMember
            memberData={memberData}
            imageOfMember={imageOfMember}
            loadingImageOfMember={loadingImageOfMember}
            imageOfWhoInvitedHim={imageOfWhoInvitedHim}
            loadingImageOfWhoInvitedHim={loadingImageOfWhoInvitedHim}
            member={currentMember}
            onClickInviteName={handleUpdateMember}
          />

          <Box sx={{ margin: "1rem 0" }}>
            <TabsCustom
              labels={labels}
              activeTab={activeTab}
              content={content}
              onChange={setActiveTab}
            />
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            padding: "1rem 33%",
          }}
        >
          <Button
            variant="outline"
            color="secondary"
            fullWidth
            onClick={onClose}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <GlobalModal
        open={isOpenGroupModal}
        title="GRUPOS TEMÁTICOS"
        description="Escolha o grupo temático que deseja vincular a esse usuário:"
        onCancel={onUpdate}
        onClose={() => setOpenGroupModal(false)}
        onConfirm={handleLinkGroup}
        cancelButtonText="Cancelar"
        confirmButtonText="Vincular grupo"
        confirmDisabled={!selectedGroupUuid}
        cancelDisabled={false}
        width="32rem"
      >
        {loadingAvailableGroups ? (
          <Text>Carregando grupos...</Text>
        ) : (
          <GroupSelect
            groups={availableGroups}
            onSelectGroup={handleGroupSelect}
          />
        )}
      </GlobalModal>
    </>
  );
};

export default ViewMemberModal;
