import React, { useCallback, useState } from "react";
import {
  Drawer,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MenuBackgroundImage from "assets/images/header/background-header-mobile.png";
import ConfirmLogoutModal from "components/Logout/components/ConfirmLogoutModal";
import Text from "components/Text";
import MeetingRoom from "pages/MeetingRoom";
import { useMeetingRooms } from "pages/ThematicGroups/hooks/useMeetingRooms";
import { useMenuTitle } from "hooks/useMenuTitleContext";
import { useIsMobile, useIsTablet } from "utils/mediaQueries";
import {
  adjustMainMenu,
  mapMenuItemsForThreeLevels,
  SidebarMenuItem,
} from "utils/sidebarUtils";
import { useUser } from "global/UserContext";
import usePingOnline from "microfrontends/hooks/usePingOnline";
import theme from "styles/theme";
import * as S from "./styles";

interface MobileMenuProps {
  mainMenu: SidebarMenuItem[];
  menuItems: SidebarMenuItem[];
}

const MobileMenu: React.FC<MobileMenuProps> = ({ mainMenu, menuItems }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { logout } = useUser();
  const { updateMenuTitle } = useMenuTitle();
  const { meetingRooms } = useMeetingRooms();
  const [drawerOpen, setDrawerOpen] = useState<string | null>(null);
  const [navigationValue, setNavigationValue] = useState(() => {
    const storedMenu = localStorage.getItem("lastSelectedMenu");
    return storedMenu || "Cockpit";
  });
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>(
    {},
  );
  const [expandedSubMenus, setExpandedSubMenus] = useState<
    Record<string, boolean>
  >({});

  const toggleDrawer = (menuType: string | null) => {
    setDrawerOpen((prevDrawer) => (prevDrawer === menuType ? null : menuType));
  };

  const handleMenuItemClick = (
    itemLabel: string,
    subItemLabel: string,
    path: string,
  ) => {
    updateMenuTitle(subItemLabel);
    localStorage.setItem("lastSelectedMenu", itemLabel);
    window.location.assign(path);
  };

  const handleSubMenuClick = (menuType: string) => {
    localStorage.setItem("lastSelectedMenu", menuType);
    setNavigationValue(menuType);
  };

  const toggleSubMenu = (title: string) => {
    setExpandedItems((prev) => ({ ...prev, [title]: !prev[title] }));
  };

  const toggleSubMenuLevelTwo = (title: string) => {
    setExpandedSubMenus((prev) => ({ ...prev, [title]: !prev[title] }));
  };

  const adjustedMainMenu = adjustMainMenu(mainMenu);

  const filteredMeetingRooms = meetingRooms.slice(0, 6);
  const reorganizedMenuItems = mapMenuItemsForThreeLevels(
    menuItems,
    filteredMeetingRooms,
  );

  const [activeDetailsMeetingRoom, setDetailsActiveMeetingRoom] = useState<{
    roomData: any;
    participantStatus: any;
  } | null>(null);

  usePingOnline();

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const handleOpenLogoutModal = () => setLogoutModalOpen(true);
  const handleCloseLogoutModal = () => setLogoutModalOpen(false);

  const handleConfirmLogout = useCallback(() => {
    setLogoutModalOpen(false);
    setTimeout(() => {
      logout();
    }, 300);
  }, [logout]);

  return (
    <>
      <BottomNavigation
        value={navigationValue}
        onChange={() => {}}
        showLabels
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: 1300,
          backgroundImage: { MenuBackgroundImage },
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* <BottomNavigationAction
          label="Menu"
          value="mainMenu"
          icon={<MenuIcon fontSize="large" />}
          onClick={() => toggleDrawer("mainMenu")}
          sx={{
            zIndex: 1301,
            borderTop:
              navigationValue === "mainMenu"
                ? `4px solid ${theme.COLORS.PRIMARY}`
                : "none",
            backgroundColor: drawerOpen === "mainMenu" ? "#494949" : "inherit",
          }}
        /> */}
        {adjustedMainMenu.map((item) => (
          <BottomNavigationAction
            key={item.title}
            label={item.label.split(" ")[0]}
            value={item.title}
            icon={
              typeof item.icon === "string" ? (
                <img
                  src={item.icon}
                  alt={item.title}
                  style={{ width: "24px", margin: "0px 0px 4px 4px" }}
                />
              ) : (
                <item.icon style={{ width: "24px", height: "24px" }} />
              )
            }
            onClick={() => toggleDrawer(item.title)}
            sx={{
              zIndex: 1301,
              borderTop:
                navigationValue === item.title
                  ? `4px solid ${theme.COLORS.PRIMARY}`
                  : "none",
              backgroundColor:
                drawerOpen === item.title ? "#494949" : "inherit",
            }}
          />
        ))}

        <BottomNavigationAction
          label="Deslogar"
          value="logout"
          icon={<PowerSettingsNewIcon fontSize="large" />}
          onClick={() => {
            toggleDrawer("logout");
            handleOpenLogoutModal();
          }}
          sx={{
            zIndex: 1301,
            borderTop:
              navigationValue === "logout"
                ? `4px solid ${theme.COLORS.PRIMARY}`
                : "none",
            backgroundColor: drawerOpen === "logout" ? "#494949" : "inherit",
          }}
        />
      </BottomNavigation>

      <Drawer
        anchor={isMobile ? "top" : "left"}
        open={drawerOpen === "mainMenu"}
        onClose={() => toggleDrawer(null)}
        sx={{
          "& .MuiDrawer-paper": {
            width: isMobile ? "100%" : "300px",
            height: isTablet ? "100vh" : "75vh",
            top: "72px",
            borderRadius: "8px",
            background: theme.COLORS.BACKGROUND_SECONDARY,
          },
        }}
      >
        <S.DrawerContent isMobile={isMobile}>
          {adjustedMainMenu.map((item) => (
            <React.Fragment key={item.title}>
              <S.MenuItem
                isActive={item.isActive}
                onClick={() => {
                  if (!item.subMenu || item.subMenu.length === 0) {
                    handleMenuItemClick("mainMenu", item.label, item.path);
                  } else {
                    toggleSubMenu(item.title);
                  }
                }}
              >
                <S.MenuIconLabel>
                  {/*                   {item?.icon && <img src={item.icon} alt={item.title} />}
                   */}{" "}
                  <Text size="medium">{item.label}</Text>
                </S.MenuIconLabel>
                {item.subMenu && item.subMenu.length > 0 && (
                  <>
                    {expandedItems[item.title] ? (
                      <ExpandLessIcon
                        fontSize="large"
                        sx={{ color: theme.COLORS.PRIMARY }}
                      />
                    ) : (
                      <ExpandMoreIcon
                        fontSize="large"
                        sx={{ color: theme.COLORS.PRIMARY }}
                      />
                    )}
                  </>
                )}
              </S.MenuItem>

              {item.subMenu && expandedItems[item.title] && (
                <S.SubMenuContainer>
                  {item.subMenu.map((subItem) => (
                    <S.SubMenuItem
                      key={subItem.title}
                      isActive={subItem.isActive}
                      onClick={() => {
                        handleSubMenuClick(item.label);
                        handleMenuItemClick(
                          "mainMenu",
                          subItem.label,
                          subItem.path,
                        );
                      }}
                    >
                      <Text size="medium">{subItem.label}</Text>
                    </S.SubMenuItem>
                  ))}
                </S.SubMenuContainer>
              )}
            </React.Fragment>
          ))}
        </S.DrawerContent>
      </Drawer>

      {adjustedMainMenu.map((item) => (
        <Drawer
          key={item.title}
          anchor={isMobile ? "top" : "left"}
          open={drawerOpen === item.title}
          onClose={() => toggleDrawer(null)}
          sx={{
            "& .MuiDrawer-paper": {
              width: isMobile ? "100%" : "300px",
              height: isTablet ? "100vh" : "75vh",
              top: "72px",
              borderRadius: "8px",
              background: theme.COLORS.BACKGROUND_SECONDARY,
            },
          }}
        >
          <S.DrawerContent isMobile={isMobile}>
            {item.subMenu?.map((subItem) => (
              <React.Fragment key={subItem.title}>
                <S.MenuItem
                  isActive={subItem.isActive}
                  onClick={() => {
                    if (!subItem.subMenu || subItem.subMenu.length === 0) {
                      handleSubMenuClick(subItem.label);
                      handleMenuItemClick(
                        item.label,
                        subItem.label,
                        subItem.path,
                      );
                    } else {
                      toggleSubMenu(subItem.title);
                    }
                  }}
                >
                  <S.MenuIconLabel>
                    {/*                     {item?.icon && <img src={item.icon} alt={item.title} />}
                     */}{" "}
                    <Text size="medium">{subItem.label}</Text>
                  </S.MenuIconLabel>
                  {subItem.subMenu && (
                    <>
                      {expandedItems[subItem.title] ? (
                        <ExpandLessIcon
                          fontSize="large"
                          sx={{ color: theme.COLORS.PRIMARY }}
                        />
                      ) : (
                        <ExpandMoreIcon
                          fontSize="large"
                          sx={{ color: theme.COLORS.PRIMARY }}
                        />
                      )}
                    </>
                  )}
                </S.MenuItem>

                {expandedItems[subItem.title] && (
                  <S.SubMenuContainer>
                    {subItem.subMenu?.map((levelThreeItem) => (
                      <React.Fragment key={levelThreeItem.title}>
                        <S.SubMenuItem
                          isActive={levelThreeItem.isActive}
                          onClick={() => {
                            if (
                              subItem.label === "Grupos Temáticos" ||
                              subItem.label === "Rodadas de Ativação"
                            ) {
                              setDetailsActiveMeetingRoom({
                                roomData: levelThreeItem.data,
                                participantStatus:
                                  levelThreeItem.participantStatus,
                              });
                            } else if (
                              !levelThreeItem.subMenu ||
                              levelThreeItem.subMenu.length === 0
                            ) {
                              handleSubMenuClick(item.label);
                              handleMenuItemClick(
                                item.label,
                                subItem.label,
                                levelThreeItem.path,
                              );
                            } else {
                              toggleSubMenuLevelTwo(levelThreeItem.title);
                            }
                          }}
                        >
                          <Text size="medium">{levelThreeItem.label}</Text>

                          {levelThreeItem.subMenu &&
                            expandedSubMenus[levelThreeItem.title] && (
                              <S.SubMenuContainer>
                                {levelThreeItem.subMenu.map((room) => (
                                  <S.SubMenuItem
                                    key={room.title}
                                    isActive={room.isActive}
                                    onClick={() => {
                                      setDetailsActiveMeetingRoom({
                                        roomData: room.data,
                                        participantStatus:
                                          room.participantStatus,
                                      });
                                    }}
                                  >
                                    <Text size="medium">{room.label}</Text>
                                  </S.SubMenuItem>
                                ))}
                              </S.SubMenuContainer>
                            )}
                        </S.SubMenuItem>
                      </React.Fragment>
                    ))}
                  </S.SubMenuContainer>
                )}
              </React.Fragment>
            ))}
          </S.DrawerContent>
        </Drawer>
      ))}

      {activeDetailsMeetingRoom && (
        <MeetingRoom
          key={activeDetailsMeetingRoom.roomData.title}
          roomData={activeDetailsMeetingRoom.roomData}
          participantStatus={activeDetailsMeetingRoom.participantStatus}
        />
      )}

      <ConfirmLogoutModal
        open={logoutModalOpen}
        onClose={handleCloseLogoutModal}
        onConfirm={handleConfirmLogout}
      />
    </>
  );
};

export default MobileMenu;
