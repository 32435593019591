export const FONT_FAMILY = {
  PRIMARY: `'Roboto', sans-serif`,
  SECONDARY: `'Poppins', sans-serif`,
};

export const FONT_SIZE = {
  XXS: "0.625rem", // 10px
  XS: "0.75rem", // 12px
  SM: "0.875rem", // 14px
  MD: "1rem", // 16px
  LG: "1.25rem", // 20px
  XL: "1.5rem", // 24px
  XXL: "2rem", // 32px
};

export const FONT_WEIGHT = {
  REGULAR: 400,
  MEDIUM: 600,
  BOLD: 700,
  BLACK: 900,
};
