import styled from "styled-components";
import theme from "styles/theme";

export const WrapperText = styled.div<{ fitContent?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ fitContent }) => (fitContent ? "fit-content" : "110px")};
  padding: ${({ fitContent }) => (fitContent ? "0 0.5rem" : "0")};
`;

export const TabText = styled.p<{
  withTitle?: boolean;
  disabled?: boolean;
  fitContent?: boolean;
  fontSize?: string;
}>`
  display: block;
  white-space: normal;
  color: ${theme.COLORS.WHITE_1};
  font-size: ${({ fontSize }) => fontSize || theme.FONT_SIZE.XS};
  font-weight: 400;
  text-align: center;
  line-height: 14px;
  width: ${({ withTitle, fitContent }) =>
    fitContent ? "fit-content" : withTitle ? "fit-content" : "108px"};
  opacity: ${({ disabled }) => (disabled ? "0.6" : "1")};
`;

export const ContentTab = styled.div`
  flex: 1;
  margin-bottom: 20px;
`;
