import React, { useRef, useState } from "react";
import { Box, Switch, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "components/Button";
import Text from "components/Text";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import { rgba } from "polished";
import { useToast } from "hooks/useToast";
import { apiRainmakers } from "services/apiService";
import theme from "styles/theme";

interface AdvancedConfigTabProps {
  eventId: string;
  onNext: () => void;
  onCancel: () => void;
}

const AdvancedConfigTab: React.FC<AdvancedConfigTabProps> = ({
  eventId,
  onNext,
  onCancel,
}) => {
  const { toastSuccess, toastError } = useToast();
  const [thumbnailEnabled, setThumbnailEnabled] = useState(false);
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileInputClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setThumbnailFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleRemoveThumbnail = () => {
    setThumbnailFile(null);
    setPreviewUrl(null);
  };

  const handleThumbnailUpload = async () => {
    if (!thumbnailFile) return true;

    try {
      const formData = new FormData();
      formData.append("file", thumbnailFile);

      const response = await apiRainmakers.post(
        `/events/v1/events/thumbnail/${eventId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      if (response.status === 200) {
        toastSuccess("Thumbnail enviado com sucesso!");
        return true;
      } else {
        throw new Error(response.data.message || "Erro desconhecido");
      }
    } catch (error) {
      console.error("Erro ao enviar a Thumbnail:", error);
      toastError("Erro ao enviar o thumbnail.");
      return false;
    }
  };

  const handleConfirmCreation = async () => {
    const success = await handleThumbnailUpload();
    if (success) {
      setSuccessOpen(true);
      setConfirmOpen(false);
    }
  };

  const handleFinalize = () => {
    setSuccessOpen(false);
    onNext();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight="300px"
    >
      <Box display="flex" alignItems="center" justifyContent="start" mb={2}>
        <Switch
          checked={thumbnailEnabled}
          onChange={(e) => setThumbnailEnabled(e.target.checked)}
          sx={{
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: theme.COLORS.PRIMARY,
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: theme.COLORS.PRIMARY,
            },
            "& .MuiSwitch-track": {
              backgroundColor: theme.COLORS.GRAY_50,
            },
          }}
        />

        <Text size="medium">Definir Thumbnail</Text>
      </Box>

      {thumbnailEnabled && (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          {thumbnailFile ? (
            <Box
              display="flex"
              flex={0.4}
              alignItems="center"
              justifyContent="start"
              gap={1}
              sx={{
                border: `1px dashed ${theme.COLORS.PRIMARY}`,
                borderRadius: 1,
                width: "200px",
                height: "56px",
                background: rgba(219, 185, 88, 0.2),
              }}
            >
              {previewUrl && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginLeft={1}
                >
                  <img
                    src={previewUrl}
                    alt="Pré-visualização"
                    style={{
                      maxHeight: "48px",
                      borderRadius: "8px",
                      border: `1px solid ${theme.COLORS.PRIMARY}`,
                    }}
                  />
                </Box>
              )}
              <Text size="xSmall" color="primary" bold>
                {thumbnailFile.name.length > 10
                  ? `${thumbnailFile.name.substring(0, 10)}...`
                  : thumbnailFile.name}{" "}
                - {(thumbnailFile.size / 1024).toFixed(1)} KB
              </Text>

              <IconButton onClick={handleRemoveThumbnail} size="small">
                <CloseIcon
                  sx={{ color: theme.COLORS.PRIMARY }}
                  fontSize="inherit"
                />
              </IconButton>
            </Box>
          ) : (
            <Box
              display="flex"
              flex={0.4}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                display="flex"
                sx={{
                  border: `1px dashed ${theme.COLORS.PRIMARY}`,
                  borderRadius: 1,
                  width: "200px",
                  height: "56px",
                  background: rgba(219, 185, 88, 0.2),
                }}
              >
                <Button
                  variant="minimal"
                  onClick={handleFileInputClick}
                  fullWidth
                >
                  Anexar Imagem Thumbnail
                </Button>
                <input
                  type="file"
                  ref={inputRef}
                  hidden
                  accept=".jpg,.png"
                  onChange={handleFileChange}
                />
              </Box>
            </Box>
          )}
          <Box display="flex" flex={0.4}>
            <Text size="XXSmall" color="gray">
              O arquivo deve conter no máximo 500KB e ser no formato .jpg .png{" "}
              {<br />}
              Dimensões: 240 x 280 px.
            </Text>
          </Box>
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" mt={4} gap="25%">
        <Button variant="outline" onClick={onCancel} fullWidth>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => setConfirmOpen(true)}
          fullWidth
        >
          Criar evento
        </Button>
      </Box>

      <GlobalModal
        open={confirmOpen}
        title="CONFIRMAÇÃO"
        description="Deseja realmente criar evento?"
        onClose={() => setConfirmOpen(false)}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={handleConfirmCreation}
        confirmButtonText="Sim"
        cancelButtonText="Não"
      />

      <GlobalModal
        open={successOpen}
        title="CONFIRMAÇÃO"
        description="Evento criado com sucesso!"
        onClose={handleFinalize}
        confirmButtonText="Fechar"
      />
    </Box>
  );
};

export default AdvancedConfigTab;
