import { useState, useEffect } from "react";
import { apiOrchestrator } from "services/apiService";
import { useToast } from "hooks/useToast";
import { AxiosError } from "axios";


interface Invite {
  id: string;
  user_id: string;
  invitee_first_name: string;
  invitee_last_name: string;
  invitee_email: string;
  invitee_cell_phone: string;
  invite_status: "pending" | "accepted" | "declined";
  invited_at: string;
  updated_at: string;
}

interface InvitesResponse {
  items: Invite[];
  total: number;
  page: number;
  size: number;
  pages: number;
}

interface InvitesProps {
  userId: any;
  page?: number;
  size?: number;
  orderBy?: string;
  isDesc?: boolean;
  inviteStatus?: string;
}

interface InviteSubmission {
  invitee_first_name: string;
  invitee_last_name: string;
  invitee_cell_phone: string;
  invitee_email: string;
  invite_status: string;
}
interface InviteUpdate {
  id: string;
  invitee_first_name?: string;
  invitee_last_name?: string;
  invitee_cell_phone?: string;
  invitee_email?: string;
  invite_status?: string;
}

export const useInvites = ({
  userId,
  page = 0,
  size = 10,
  orderBy,
  isDesc,
  inviteStatus,
}: InvitesProps) => {
  const [invites, setInvites] = useState<Invite[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { toastError } = useToast();

  useEffect(() => {
    if (userId) {
      fetchInvites(userId);
    }
  }, [userId, page, size, orderBy, isDesc, inviteStatus]);

  async function fetchInvites(userId: string) {
    if (!userId) {
      console.warn("fetchInvites called without a valid userId");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const params: { [key: string]: any } = {
        user_id: userId,
        page: page + 1,
        size: size,
      };

      // Adicione apenas parâmetros definidos
      if (orderBy) {
        params.order_by = orderBy;
      }
      if (isDesc !== undefined) {
        params.is_desc = isDesc;
      }
      if (inviteStatus) {
        params.invite_status = inviteStatus;
      }

      const resp = await apiOrchestrator.get<InvitesResponse>(`/invites/`, {
        params: params,
      });

      setInvites(resp.data.items);
      setTotal(resp.data.total);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch invites");
    } finally {
      setLoading(false);
    }
  }

  async function submitInvite(props: InviteSubmission) {
    if (!userId) {
      console.warn("submitInvite called without a valid userId");
      return;
    }

    try {
      await apiOrchestrator.post(`/invites/${userId}`, props);
      await fetchInvites(userId); // Atualiza a lista de convites
    } catch (err) {
      console.error("Failed to submit invite", err);
      setError("Failed to submit invite");
    }
  }

  async function updateInvite(props: InviteUpdate) {
    if (!userId) {
      console.warn("updateInvite called without a valid userId");
      return;
    }

    try {
      await apiOrchestrator.patch(`/invites/${props.id}`, props);
      await fetchInvites(userId); // Atualiza a lista de convites
    } catch (err) {
      console.error("Failed to update invite", err);
      setError("Failed to update invite");
    }
  }

  async function deleteInvite(inviteId: string) {
    if (!userId) {
      console.warn("deleteInvite called without a valid userId");
      return;
    }

    try {
      await apiOrchestrator.delete(`/invites/${inviteId}`);
    } catch (err) {
      console.error("Failed to delete invite", err);

      const error = err as AxiosError<{ detail?: string }>;
      console.log({ error })

      toastError(error.response?.data?.detail || "Ocorreu um erro ao deletar o convite.");
    } finally {
      await fetchInvites(userId); // Atualiza a lista de convites
    }
  }

  async function getGeneralInviteId() {
    if (!userId) {
      console.warn("getGeneralInviteId called without a valid userId");
      return null;
    }

    try {
      const generalInviteId = await apiOrchestrator.get(
        `/invites/general-invite/${userId}`,
      );
      return generalInviteId.data;
    } catch (err) {
      console.error("Failed to get general invite ID", err);
      setError("Failed to get general invite ID");
      return null;
    }
  }

  return {
    invites,
    total,
    loading,
    error,
    submitInvite,
    updateInvite,
    fetchInvites,
    deleteInvite,
    getGeneralInviteId,
  };
};
