import React from "react";
import { Box, LinearProgress } from "@mui/material";
import Text from "components/Text";
import theme from "styles/theme";

interface FormCompletionItemProps {
  formName: string;
  completionPercentage: number;
}

const FormCompletionItem: React.FC<FormCompletionItemProps> = ({
  formName,
  completionPercentage,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.5rem",
        backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
        borderRadius: "8px",
        marginBottom: "0.5rem",
        minHeight: "3.5rem",
      }}
    >
      <Box sx={{ flex: 2, paddingLeft: "1rem" }}>
        <Text size="medium">{formName}</Text>
      </Box>

      <Box
        sx={{
          flex: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: "1rem",
          marginRight: "1rem",
        }}
      >
        <LinearProgress
          variant="determinate"
          value={completionPercentage}
          sx={{
            flex: 1.7,
            height: "8px",
            borderRadius: "4px",
            backgroundColor: theme.COLORS.GRAY_90,
            "& .MuiLinearProgress-bar": {
              backgroundColor: theme.COLORS.PRIMARY,
            },
          }}
        />
      </Box>
      <Box
        sx={{
          flex: 0.3,
          textAlign: "right",
          width: "100%",
          paddingRight: "0.25rem",
        }}
      >
        <Text size="medium" color="primary" fontWeight={500} textAlign="right">
          {completionPercentage}%
        </Text>
      </Box>
    </Box>
  );
};

export default FormCompletionItem;
