import React, { useState } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import Text from "components/Text";
import { Group } from "pages/DigitalGovernance/GovernanceThematicGroups/types/groups";
import theme from "styles/theme";

interface GroupSelectProps {
  groups: Group[];
  onSelectGroup: (groupUuid: string | null) => void;
}

const sharedStyles = {
  menuItem: {
    background: theme.COLORS.BACKGROUND_PRIMARY,
    padding: 0,
    paddingLeft: 2,
    "&.Mui-selected": {
      backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
    },
  },
};

const GroupSelect: React.FC<GroupSelectProps> = ({ groups, onSelectGroup }) => {
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectedGroup(value);
    onSelectGroup(value);
  };

  return (
    <FormControl fullWidth variant="outlined" sx={{ marginTop: 2 }}>
      <Select
        value={selectedGroup || ""}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Selecionar Grupo Temático" }}
        renderValue={(selected) => {
          if (!selected) {
            return <Text>Selecionar Grupo Temático</Text>;
          }
          const selectedGroupName =
            groups.find((group) => group.uuid === selected)?.name || "";
          return <Text>{selectedGroupName}</Text>;
        }}
      >
        {groups.map((group) => (
          <MenuItem
            key={group.uuid}
            value={group.uuid}
            sx={sharedStyles.menuItem}
          >
            {group.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GroupSelect;
