import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  TextField,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import Text from "components/Text";
import Spin from "components/Spin";
import Button from "components/Button";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import { apiOrchestrator, apiRainmakers } from "services/apiService";
import useUnassignedUsers from "../../../hooks/useUnassignedUsers";
import { useToast } from "hooks/useToast";
import { Group, User } from "../../../types/groups";
import theme from "styles/theme";
import CustomTooltip from "./components/CustomTooltip";
import { SearchService } from "./styles";

interface ParticipantTabProps {
  eventId: string;
  onNext: () => void;
  onCancel: () => void;
  isEditMode?: boolean;
  setEventData: any;
}

const ParticipantTab: React.FC<ParticipantTabProps> = ({
  eventId,
  onNext,
  onCancel,
  isEditMode = false,
  setEventData,
}) => {
  const { unassignedUsers, loading, error } = useUnassignedUsers();
  const { toastSuccess, toastError } = useToast();
  const [groups, setGroups] = useState<Group[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());
  const [tab, setTab] = useState<"PARTICIPANTS" | "PRESENTERS" | "COORDINATOR">(
    "PARTICIPANTS",
  );
  const [searchTerm, setSearchTerm] = useState("");

  const currentRole =
    tab === "PARTICIPANTS"
      ? "PARTICIPANT"
      : tab === "PRESENTERS"
        ? "PRESENTER"
        : "COORDINATOR";

  const otherRole =
    tab === "PARTICIPANTS"
      ? "COORDINATOR"
      : tab === "PRESENTERS"
        ? "COORDINATOR"
        : "PARTICIPANT";

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUserForChange, setSelectedUserForChange] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [groupRes, userRes] = await Promise.all([
          apiOrchestrator.get("/team/all"),
          apiOrchestrator.get("/user_to_organization/members"),
        ]);
        setGroups(groupRes.data);
        setUsers(
          userRes.data.map((u: any) => ({
            id: u.user.id,
            first_name: u.user.first_name,
            last_name: u.user.last_name,
          })),
        );
      } catch (error) {
        toastError("Erro ao buscar grupos ou usuários.");
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchSelectedParticipants = async () => {
      try {
        const res = await apiRainmakers.get(
          `/events/v1/participants/${eventId}`,
        );
        setSelected(res.data);
      } catch (error) {
        toastError("Erro ao buscar participantes.");
        console.error(error);
      }
    };

    fetchSelectedParticipants();
  }, [eventId]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredData = () => {
    const filteredGroups = groups.filter((group) =>
      group.name.toLowerCase().includes(searchTerm),
    );

    const filteredUsers = users
      .filter((user) =>
        `${user.first_name} ${user.last_name}`
          .toLowerCase()
          .includes(searchTerm),
      )
      .filter(
        (user) =>
          !selected.some(
            (item) => item.user_uuid === user.id && item.role === otherRole,
          ),
      );

    return { filteredGroups, filteredUsers };
  };

  const { filteredGroups, filteredUsers } = filteredData();

  const filteredUnassignedUsers = unassignedUsers.filter((user) =>
    `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm),
  );

  const filteredPresenters = users.filter((user) =>
    `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm),
  );

  const filteredCoordinators = users.filter((user) =>
    `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm),
  );

  const toggleGroupExpansion = (groupId: string) => {
    setExpandedGroups((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(groupId)) {
        newSet.delete(groupId);
      } else {
        newSet.add(groupId);
      }
      return newSet;
    });
  };

  const handleToggleGroup = (group: Group | null) => {
    const role = currentRole;

    if (group?.uuid === "unassigned") {
      // Tratamento especial para "Usuários sem grupo"
      const allUnassignedSelected = unassignedUsers.every((user) =>
        selected.some(
          (item) => item.user_uuid === user.id && item.role === role,
        ),
      );

      if (allUnassignedSelected) {
        // Remover todos os usuários sem grupo do papel atual
        setSelected((prev) =>
          prev.filter(
            (item) =>
              !unassignedUsers.some(
                (user) => user.id === item.user_uuid && item.role === role,
              ),
          ),
        );
      } else {
        // Remover usuários sem grupo de papéis conflitantes
        setSelected((prev) =>
          prev.filter(
            (item) =>
              !unassignedUsers.some((user) => user.id === item.user_uuid),
          ),
        );

        // Adicionar usuários sem grupo ao papel atual
        const newSelections = unassignedUsers.map((user) => ({
          user_uuid: user.id,
          role,
          is_blocked: false,
        }));

        setSelected((prev) => [...prev, ...newSelections]);
      }
    } else if (group) {
      // Tratamento padrão para outros grupos
      const allGroupMembersSelected = group.members.every((member) =>
        selected.some(
          (item) => item.user_uuid === member.uuid && item.role === role,
        ),
      );

      if (allGroupMembersSelected) {
        // Remover todos os membros do grupo do papel atual
        setSelected((prev) =>
          prev.filter(
            (item) =>
              !group.members.some(
                (member) =>
                  member.uuid === item.user_uuid && item.role === role,
              ),
          ),
        );
      } else {
        // Remover membros do grupo de papéis conflitantes
        setSelected((prev) =>
          prev.filter(
            (item) =>
              !group.members.some((member) => member.uuid === item.user_uuid),
          ),
        );

        // Adicionar membros do grupo ao papel atual
        const newSelections = group.members.map((member) => ({
          user_uuid: member.uuid,
          role,
          is_blocked: false,
        }));

        setSelected((prev) => [...prev, ...newSelections]);
      }
    }
  };

  const handleToggleUser = (userId: string, role: string) => {
    const alreadySelected = selected.some(
      (item) => item.user_uuid === userId && item.role === role,
    );

    if (alreadySelected) {
      setSelected((prev) =>
        prev.filter(
          (item) => !(item.user_uuid === userId && item.role === role),
        ),
      );
    } else {
      setSelected((prev) =>
        prev
          .filter((item) => item.user_uuid !== userId)
          .concat({
            user_uuid: userId,
            role,
            is_blocked: false,
          }),
      );
    }
  };

  const handleRemoveGroup = (groupId: string) => {
    const role = currentRole;
    setSelected((prev) =>
      prev.filter(
        (item) =>
          !groups
            .find((group) => group.uuid === groupId)
            ?.members.some(
              (member) => member.uuid === item.user_uuid && item.role === role,
            ),
      ),
    );
  };

  const handleRemoveUser = (userId: string) => {
    const role = currentRole;
    setSelected((prev) =>
      prev.filter((item) => !(item.user_uuid === userId && item.role === role)),
    );
  };

  const handleSave = async () => {
    try {
      const uniqueSelected = Array.from(
        new Map(
          selected.map((item) => [`${item.user_uuid}-${item.role}`, item]),
        ).values(),
      );

      let updatedParticipants: Array<{
        user_uuid: string;
        role: string;
        is_blocked: boolean;
        status?: string;
      }> = [];

      if (isEditMode) {
        const response = await apiRainmakers.patch(
          `/events/v1/participants/${eventId}`,
          uniqueSelected,
        );
        updatedParticipants = response.data;
        toastSuccess("Participantes atualizados com sucesso!");
      } else {
        const response = await apiRainmakers.post(
          `/events/v1/participants/${eventId}`,
          uniqueSelected,
        );
        updatedParticipants = response.data;
        toastSuccess("Participantes salvos com sucesso!");
      }

      setEventData((prev: any) => ({
        ...prev,
        participants: {
          participants: updatedParticipants,
          total: updatedParticipants.length,
          total_accepted: updatedParticipants.filter(
            (p) => p.status === "accepted",
          ).length,
          total_declined: updatedParticipants.filter(
            (p) => p.status === "declined",
          ).length,
          total_pending: updatedParticipants.filter(
            (p) => p.status === "pending",
          ).length,
        },
      }));

      onNext();
    } catch (error) {
      toastError("Erro ao salvar alterações.");
      console.error(error);
    }
  };

  const handleSelectAll = () => {
    const role = currentRole;

    const allGroupMemberIds = groups.flatMap((group) =>
      group.members.map((member) => member.uuid),
    );
    const allUnassignedUserIds = unassignedUsers.map((user) => user.id);

    const allIds = [...allGroupMemberIds, ...allUnassignedUserIds];

    setSelected((prev) =>
      prev.filter((item) => !allIds.includes(item.user_uuid)),
    );

    const newSelections = allIds.map((userId) => ({
      user_uuid: userId,
      role,
      is_blocked: false,
    }));

    setSelected((prev) => [...prev, ...newSelections]);
  };

  const handleClearSelection = () => {
    const role = currentRole;

    setSelected((prev) => prev.filter((item) => item.role !== role));
  };

  const handleToggleUserWithConfirmation = (userId: string, role: string) => {
    const existingSelection = selected.find(
      (item) => item.user_uuid === userId,
    );

    if (existingSelection && existingSelection.role !== role) {
      const user = users.find((u) => u.id === userId) || {
        first_name: "Usuário",
        last_name: "Desconhecido",
      };
      setSelectedUserForChange({
        user_uuid: userId,
        oldRole: existingSelection.role,
        newRole: role,
        name: `${user.first_name} ${user.last_name}`,
      });
      setModalOpen(true);
    } else {
      handleToggleUser(userId, role);
    }
  };

  const handleToggleCoordinator = (userId: string) => {
    const existingSelection = selected.find(
      (item) => item.user_uuid === userId,
    );

    if (existingSelection && existingSelection.role !== "COORDINATOR") {
      const user = users.find((u) => u.id === userId) || {
        first_name: "Usuário",
        last_name: "Desconhecido",
      };
      setSelectedUserForChange({
        user_uuid: userId,
        oldRole: existingSelection.role,
        newRole: "COORDINATOR",
        name: `${user.first_name} ${user.last_name}`,
      });
      setModalOpen(true);
      return;
    }

    setSelected((prev) => [
      ...prev.filter((item) => item.role !== "COORDINATOR"),
      { user_uuid: userId, role: "COORDINATOR", is_blocked: false },
    ]);
  };

  const confirmRoleChange = () => {
    if (selectedUserForChange) {
      const { user_uuid, newRole } = selectedUserForChange;

      setSelected((prev) => [
        ...prev.filter(
          (item) => item.user_uuid !== user_uuid && item.role !== "COORDINATOR",
        ),
        { user_uuid, role: newRole, is_blocked: false },
      ]);

      setModalOpen(false);
      setSelectedUserForChange(null);
    }
  };

  const roleTranslations: Record<string, string> = {
    PARTICIPANT: "Participante",
    PRESENTER: "Palestrante",
    COORDINATOR: "Coordenador",
  };

  const renderGroup = (group: Group) => {
    const groupMembers = group.members.map((member) => member.uuid);

    const allSelected = groupMembers.every((uuid) =>
      selected.some(
        (item) => item.user_uuid === uuid && item.role === currentRole,
      ),
    );

    const someSelected =
      groupMembers.some((uuid) =>
        selected.some(
          (item) => item.user_uuid === uuid && item.role === currentRole,
        ),
      ) && !allSelected;

    return loading ? (
      <Spin centerHorizontally centerVertically />
    ) : (
      <Box
        key={group.uuid}
        sx={{
          borderRadius: 1,
          border: `1px solid ${theme.COLORS.GRAY_10}`,
          mb: 1,
        }}
      >
        <ListItem sx={{ padding: 0 }}>
          <Checkbox
            checked={allSelected}
            indeterminate={someSelected}
            onChange={() => handleToggleGroup(group)}
            sx={{
              color: theme.COLORS.PRIMARY,
              "&.Mui-checked": { color: theme.COLORS.PRIMARY },
              "&.MuiCheckbox-indeterminate": { color: theme.COLORS.PRIMARY },
            }}
          />
          <ListItemText primary={group.name} />
          <IconButton onClick={() => toggleGroupExpansion(group.uuid)}>
            {expandedGroups.has(group.uuid) ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </IconButton>
        </ListItem>
        <Collapse
          in={expandedGroups.has(group.uuid)}
          timeout="auto"
          unmountOnExit
        >
          <List sx={{ pl: 2 }}>
            {group.members.map((member) => (
              <ListItem key={member.uuid} dense sx={{ padding: 0 }}>
                <Checkbox
                  checked={selected.some(
                    (item) =>
                      item.user_uuid === member.uuid &&
                      item.role === currentRole,
                  )}
                  onChange={() => handleToggleUser(member.uuid, currentRole)}
                  sx={{
                    color: theme.COLORS.PRIMARY,
                    "&.Mui-checked": { color: theme.COLORS.PRIMARY },
                    "&.MuiCheckbox-indeterminate": {
                      color: theme.COLORS.PRIMARY,
                    },
                  }}
                />
                <ListItemText
                  primary={`${member.first_name} ${member.last_name}`}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </Box>
    );
  };

  const renderUnassignedUsers = () => {
    const allSelected = unassignedUsers.every((user) =>
      selected.some(
        (item) => item.user_uuid === user.id && item.role === currentRole,
      ),
    );

    const someSelected =
      unassignedUsers.some((user) =>
        selected.some(
          (item) => item.user_uuid === user.id && item.role === currentRole,
        ),
      ) && !allSelected;

    return loading ? (
      <Spin centerHorizontally centerVertically />
    ) : (
      <Box
        sx={{
          borderRadius: 1,
          border: `1px solid ${theme.COLORS.GRAY_10}`,
          mb: 1,
        }}
      >
        <ListItem sx={{ padding: 0 }}>
          <Checkbox
            checked={allSelected}
            indeterminate={someSelected}
            onChange={() => handleToggleGroup({ uuid: "unassigned" } as Group)}
            sx={{
              color: theme.COLORS.PRIMARY,
              "&.Mui-checked": { color: theme.COLORS.PRIMARY },
              "&.MuiCheckbox-indeterminate": { color: theme.COLORS.PRIMARY },
            }}
          />
          <ListItemText primary="Usuários sem grupo" />
          <IconButton onClick={() => toggleGroupExpansion("unassigned")}>
            {expandedGroups.has("unassigned") ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </IconButton>
        </ListItem>
        <Collapse
          in={expandedGroups.has("unassigned")}
          timeout="auto"
          unmountOnExit
        >
          <List sx={{ pl: 2 }}>
            {unassignedUsers.map(
              (
                user, // option for filteredUnassignedUsers
              ) => (
                <ListItem key={user.id} dense sx={{ padding: 0 }}>
                  <Checkbox
                    checked={selected.some(
                      (item) =>
                        item.user_uuid === user.id && item.role === currentRole,
                    )}
                    onChange={() =>
                      handleToggleUserWithConfirmation(user.id, currentRole)
                    }
                    sx={{
                      color: theme.COLORS.PRIMARY,
                      "&.Mui-checked": { color: theme.COLORS.PRIMARY },
                      "&.MuiCheckbox-indeterminate": {
                        color: theme.COLORS.PRIMARY,
                      },
                    }}
                  />
                  <ListItemText
                    primary={`${user.first_name} ${user.last_name}`}
                  />
                </ListItem>
              ),
            )}
          </List>
        </Collapse>
      </Box>
    );
  };

  const renderPresenters = () => {
    return filteredPresenters.map((user) => (
      <ListItem key={user.id} dense sx={{ padding: 0 }}>
        <Checkbox
          checked={selected.some(
            (item) => item.user_uuid === user.id && item.role === "PRESENTER",
          )}
          onChange={() =>
            handleToggleUserWithConfirmation(user.id, "PRESENTER")
          }
          sx={{
            color: theme.COLORS.PRIMARY,
            "&.Mui-checked": { color: theme.COLORS.PRIMARY },
            "&.MuiCheckbox-indeterminate": { color: theme.COLORS.PRIMARY },
          }}
        />
        <ListItemText primary={`${user.first_name} ${user.last_name}`} />
      </ListItem>
    ));
  };

  const renderCoordinators = () => {
    return filteredCoordinators.map((user) => (
      <ListItem key={user.id} dense sx={{ padding: 0 }}>
        <Radio
          checked={selected.some(
            (item) => item.user_uuid === user.id && item.role === "COORDINATOR",
          )}
          onChange={() => handleToggleCoordinator(user.id)}
          sx={{
            color: theme.COLORS.PRIMARY,
            "&.Mui-checked": { color: theme.COLORS.PRIMARY },
          }}
        />
        <ListItemText primary={`${user.first_name} ${user.last_name}`} />
      </ListItem>
    ));
  };

  return (
    <Box>
      <Box display="flex" maxWidth="100%" gap="0" mb="1rem">
        <Button
          size="XSmall"
          variant={tab === "PARTICIPANTS" ? "primary" : "outline"}
          onClick={() => setTab("PARTICIPANTS")}
          fullWidth
          style={{ borderRadius: "8px 0px 0px 8px" }}
        >
          Definir Participantes
        </Button>
        <Button
          size="XSmall"
          variant={tab === "PRESENTERS" ? "primary" : "outline"}
          onClick={() => setTab("PRESENTERS")}
          fullWidth
          style={{ borderRadius: 0 }}
        >
          Definir Palestrantes
        </Button>
        <Button
          size="XSmall"
          variant={tab === "COORDINATOR" ? "primary" : "outline"}
          onClick={() => setTab("COORDINATOR")}
          fullWidth
          style={{ borderRadius: "0px 8px 8px 0px" }}
        >
          Definir Coordenadores
        </Button>
      </Box>

      <Box display="flex" gap="2rem">
        <Box width="50%">
          <Box
            sx={{
              display: "flex",
              color: theme.COLORS.TEXT_PRIMARY,
              fontFamily: "Roboto",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "1.5rem",
            }}
          >
            <span>
              {tab === "PARTICIPANTS"
                ? "Selecionar Participantes"
                : tab === "PRESENTERS"
                  ? "Selecionar Palestrantes"
                  : "Selecionar Coordenador"}
            </span>
            {tab === "PARTICIPANTS" && <CustomTooltip />}
          </Box>
          <Box
            sx={{
              border: `1px solid ${theme.COLORS.GRAY_10}`,
              borderRadius: "8px",
              overflow: "auto",
              height: "20rem",
              padding: 0.5,
              mt: 0.5,
            }}
          >
            <TextField
              variant="outlined"
              placeholder={`Buscar`}
              value={searchTerm}
              onChange={handleSearchChange}
              size="small"
              fullWidth
              sx={SearchService}
            />

            {tab === "PARTICIPANTS" && (
              <Button
                size="XXSmall"
                variant="minimal"
                onClick={handleSelectAll}
                style={{ padding: 0 }}
              >
                Selecionar todos
              </Button>
            )}

            <List>
              {tab === "PARTICIPANTS" && (
                <>
                  {filteredGroups.map(renderGroup)}

                  {renderUnassignedUsers()}

                  {filteredUsers.length !== users.length && (
                    <List>
                      {filteredUsers.map((user) => (
                        <ListItem key={user.id} dense sx={{ padding: 0 }}>
                          <Checkbox
                            checked={selected.some(
                              (item) =>
                                item.user_uuid === user.id &&
                                item.role === currentRole,
                            )}
                            onChange={() =>
                              handleToggleUserWithConfirmation(
                                user.id,
                                currentRole,
                              )
                            }
                            sx={{
                              color: theme.COLORS.PRIMARY,
                              "&.Mui-checked": {
                                color: theme.COLORS.PRIMARY,
                              },
                              "&.MuiCheckbox-indeterminate": {
                                color: theme.COLORS.PRIMARY,
                              },
                            }}
                          />
                          <ListItemText
                            primary={`${user.first_name} ${user.last_name}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </>
              )}
              {tab === "PRESENTERS" && <>{renderPresenters()}</>}
              {tab === "COORDINATOR" && <>{renderCoordinators()}</>}
            </List>
          </Box>
        </Box>

        {tab === "PARTICIPANTS" && (
          <Box width="50%">
            <Text>Usuários e Grupos selecionados</Text>
            <Box
              sx={{
                border: `1px solid ${theme.COLORS.GRAY_10}`,
                borderRadius: "8px",
                overflow: "auto",
                height: "20rem",
                mt: 0.8,
              }}
            >
              <Button
                size="XXSmall"
                variant="minimal"
                onClick={handleClearSelection}
                disabled={selected.length === 0}
                style={{ padding: "0.5rem 0 0 0.5rem" }}
              >
                Limpar seleção
              </Button>

              <List sx={{ marginLeft: 1, marginRight: 1 }}>
                {groups.map((group) => {
                  const groupParticipants = group.members.filter((member) =>
                    selected.some(
                      (item) =>
                        item.user_uuid === member.uuid &&
                        item.role === currentRole,
                    ),
                  );

                  if (groupParticipants.length > 0) {
                    return (
                      <React.Fragment key={group.uuid}>
                        <ListItem
                          sx={{
                            background: theme.COLORS.BACKGROUND_PRIMARY,
                            marginTop: "4px",
                            gap: 1,
                            padding: 0,
                            borderRadius: 1,
                          }}
                        >
                          <Checkbox
                            checked
                            disabled
                            sx={{
                              color: theme.COLORS.PRIMARY,
                              "&.Mui-checked": {
                                color: theme.COLORS.PRIMARY,
                              },
                              "&.MuiCheckbox-indeterminate": {
                                color: theme.COLORS.PRIMARY,
                              },
                            }}
                          />
                          <ListItemText primary={group.name} />
                          <IconButton
                            onClick={() => handleRemoveGroup(group.uuid)}
                          >
                            <CloseIcon
                              htmlColor={theme.COLORS.PRIMARY}
                              fontSize="inherit"
                              sx={{ fontSize: 10 }}
                            />
                          </IconButton>
                        </ListItem>
                        <List
                          sx={{
                            background: theme.COLORS.BACKGROUND_PRIMARY,
                            gap: 1,
                            padding: 0,
                          }}
                        >
                          {groupParticipants.map((member) => (
                            <ListItem
                              key={member.uuid}
                              dense
                              sx={{ padding: 0, pl: 1 }}
                            >
                              <Checkbox
                                checked
                                onChange={() =>
                                  handleToggleUserWithConfirmation(
                                    member.id,
                                    currentRole,
                                  )
                                }
                                sx={{
                                  color: theme.COLORS.PRIMARY,
                                  "&.Mui-checked": {
                                    color: theme.COLORS.PRIMARY,
                                  },
                                  "&.MuiCheckbox-indeterminate": {
                                    color: theme.COLORS.PRIMARY,
                                  },
                                }}
                              />
                              <ListItemText
                                primary={`${member.first_name} ${member.last_name}`}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </React.Fragment>
                    );
                  }

                  return null;
                })}

                <Box
                  sx={{
                    background: theme.COLORS.BACKGROUND_PRIMARY,
                    marginTop: 0.5,
                    gap: 1,
                    padding: 0,
                    borderRadius: 1,
                  }}
                >
                  {selected
                    .filter(
                      (item) =>
                        item.role === currentRole &&
                        !groups.some((group) =>
                          group.members.some(
                            (member) => member.uuid === item.user_uuid,
                          ),
                        ),
                    )
                    .map((participant) => {
                      const user = users.find(
                        (user) => user.id === participant.user_uuid,
                      );
                      return (
                        <ListItem key={participant.user_uuid} dense>
                          <Checkbox
                            checked
                            onChange={() =>
                              handleRemoveUser(participant.user_uuid)
                            }
                            sx={{
                              color: theme.COLORS.PRIMARY,
                              "&.Mui-checked": {
                                color: theme.COLORS.PRIMARY,
                              },
                              "&.MuiCheckbox-indeterminate": {
                                color: theme.COLORS.PRIMARY,
                              },
                            }}
                          />
                          <ListItemText
                            primary={`${user?.first_name} ${user?.last_name}`}
                          />
                          <IconButton
                            onClick={() =>
                              handleRemoveUser(participant.user_uuid)
                            }
                          >
                            <CloseIcon
                              htmlColor={theme.COLORS.PRIMARY}
                              fontSize="inherit"
                              sx={{ fontSize: 10 }}
                            />
                          </IconButton>
                        </ListItem>
                      );
                    })}
                </Box>
              </List>
            </Box>
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt="1rem"
        gap="25%"
      >
        <Button variant="outline" onClick={onCancel} fullWidth>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave} fullWidth>
          {isEditMode ? "Salvar alterações" : "Config Avançadas"}
        </Button>
      </Box>

      <GlobalModal
        open={modalOpen}
        title="CONFIRMAÇÃO"
        description=""
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={confirmRoleChange}
        onCancel={() => setModalOpen(false)}
        onClose={() => setModalOpen(false)}
      >
        <Text>
          O usuário <strong>{selectedUserForChange?.name}</strong> já foi
          selecionado como{" "}
          <strong>
            {roleTranslations[selectedUserForChange?.oldRole] || "desconhecido"}
          </strong>
          . Deseja alterá-lo para{" "}
          <strong>
            {roleTranslations[selectedUserForChange?.newRole] || "desconhecido"}
          </strong>
          ?
        </Text>
      </GlobalModal>
    </Box>
  );
};

export default ParticipantTab;
