import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { Box, CircularProgress, TextField } from "@mui/material";
import Text from "components/Text";
import Spin from "components/Spin";
import InternalButton from "components/InternalButton";
import MemberList from "./components/MemberList";
import FilterModal from "./components/FilterModal";
import ModalNovaIndicacao from "pages/UserProfile/Invites/NovaIndicacaoModal";
import useOrganizationMembers from "./hooks/useOrganizationMembers";
import { apiOrchestrator } from "services/apiService";
import { exportToCSV } from "./hooks/exportToCSV";
import { useFilterOptions } from "./hooks/useFilterOptions";
import { useToast } from "hooks/useToast";
import { OrganizationMember } from "./types/users";
import { useUser } from "global/UserContext";
import theme from "styles/theme";
import * as S from "../styles";

const GovernanceUsers: React.FC = () => {
  const { toastSuccess } = useToast();
  const { user, permissions: contextPermissions } = useUser();
  const { members, loading, error, loadMembers } = useOrganizationMembers();
  const { permissions, plans, groups, invitedBy } = useFilterOptions(members);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMembersModal, setFilteredMembersModal] = useState(members);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [isOpenModalNewIndication, setOpenModalNewIndication] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [formValues, setFormValues] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
  });

  const isOwnerAccess = contextPermissions.includes("OwnerAccess");
  const isAdministratorAccess = contextPermissions.includes(
    "AdministratorAccess",
  );
  const isClubAccess = contextPermissions.includes("ClubAccess");

  useEffect(() => {
    if (members.length > 0) {
      setFilteredMembersModal(members);
    }
  }, [members]);

  if (loading) return <Spin fullscreen />;

  if (error)
    return (
      <Text color="red" bold>
        Erro ao carregar usuários.
      </Text>
    );

  const handleFilterApply = (filters: Record<string, string[]>) => {
    const { permission, plan, group, invitedBy } = filters;

    const filtered = members.filter((member) => {
      const memberPermission = member.group_role?.uuid || "N/A";
      const memberPlan = member.individual_plan?.uuid || "N/A";
      const memberGroups = member.teams?.map((t) => t.team_uuid) || [];
      const memberInvitedBy = member.invited_by?.user_uuid || "N/A";

      return (
        (!permission.length || permission.includes(memberPermission)) &&
        (!plan.length || plan.includes(memberPlan)) &&
        (!group.length || memberGroups.some((uuid) => group.includes(uuid))) &&
        (!invitedBy.length || invitedBy.includes(memberInvitedBy))
      );
    });

    console.log("Filters applied:", filters);
    console.log("Filtered Members:", filtered);
    setFilteredMembersModal(filtered);
  };

  const handleClickNewIndication = () => {
    setFormValues({ firstName: "", lastName: "", email: "" });
    setOpenModalNewIndication(true);
  };

  const handleCloseModalNewIndication = () => {
    setOpenModalNewIndication(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredMembers = filteredMembersModal.filter(
    (member: OrganizationMember) => {
      const fullName =
        `${member.user.first_name} ${member.user.last_name}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    },
  );

  const handleExport = async () => {
    setIsDownloading(true);
    const fetchMemberData = async (memberId: string) => {
      try {
        const response = await apiOrchestrator.get(`/users-person/${memberId}`);
        return response.data;
      } catch (error) {
        console.error(`Erro ao buscar dados do membro ${memberId}:`, error);
        return null;
      }
    };

    const detailedMembers = await Promise.all(
      filteredMembers.map(async (member) => {
        const memberData = await fetchMemberData(member.user?.id);
        return {
          ...member,
          email: memberData?.email || "N/A",
          whatsapp_number: memberData?.whatsapp_number || "N/A",
        };
      }),
    );

    exportToCSV(detailedMembers, "UsuariosFiltrados", () => {
      toastSuccess("Download realizado com sucesso!");
    });

    setIsDownloading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        my: 0,
        padding: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "0.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "0.3",
            flexDirection: "row",
            justifyContent: "left",
          }}
        >
          <Text size="large" textAlign="left">
            Usuários ({filteredMembers.length})
          </Text>
          <TextField
            variant="outlined"
            placeholder="Buscar por Usuário"
            value={searchTerm}
            onChange={handleSearchChange}
            size="small"
            sx={S.SearchService}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "0.75rem",
            flex: "0.7",
            justifyContent: "flex-end",
          }}
        >
          <InternalButton
            size="small"
            background={theme.COLORS.PRIMARY}
            textColor={theme.COLORS.PRIMARY}
            variant="outline"
            iconPosition="left"
            icon={<FilterListOutlinedIcon sx={{ fontSize: "1.2rem" }} />}
            onClick={() => setOpenFilterModal(true)}
          >
            Filtrar
          </InternalButton>

          {isOwnerAccess && (
            <InternalButton
              size="small"
              background={theme.COLORS.PRIMARY}
              textColor={theme.COLORS.PRIMARY}
              variant="outline"
              iconPosition="left"
              icon={
                isDownloading ? (
                  <CircularProgress
                    size="1rem"
                    sx={{ color: theme.COLORS.WHITE }}
                  />
                ) : (
                  <FileDownloadOutlinedIcon sx={{ fontSize: "1.2rem" }} />
                )
              }
              onClick={handleExport}
              disabled={isDownloading}
            >
              {isDownloading
                ? "Preparando Download..."
                : "Download da listagem"}
            </InternalButton>
          )}

          <InternalButton
            size="small"
            background={theme.COLORS.PRIMARY}
            textColor={theme.COLORS.BLACK}
            variant="primary"
            iconPosition="left"
            icon={<AddIcon sx={{ fontSize: "1.2rem" }} />}
            onClick={handleClickNewIndication}
          >
            Nova Indicação
          </InternalButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          flexDirection: "column",
          minHeight: "300px",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <Spin />
          </Box>
        ) : filteredMembers.length === 0 ? (
          <Box sx={{ marginTop: 1 }}>
            <Text color="gray">
              {searchTerm
                ? "Nenhum usuário encontrado."
                : "Nenhum usuário encontrado."}
            </Text>
          </Box>
        ) : (
          <MemberList
            members={filteredMembers}
            searchTerm={searchTerm}
            onUpdate={loadMembers}
          />
        )}
      </Box>

      <FilterModal
        open={openFilterModal}
        onClose={() => setOpenFilterModal(false)}
        onApply={handleFilterApply}
        permissions={permissions}
        plans={plans}
        groups={groups}
        invitedBy={invitedBy}
      />

      {isOpenModalNewIndication && (
        <ModalNovaIndicacao
          handleCloseModal={handleCloseModalNewIndication}
          initialFormValues={formValues}
        />
      )}
    </Box>
  );
};

export default GovernanceUsers;
