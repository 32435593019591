import React, { createContext, useContext, ReactNode } from 'react';
import { toast } from 'react-toastify';

// Create a context to provide toast functions
export const ToastContext = createContext<{
    toastSuccess: (message: string) => void;
    toastError: (message: string) => void;
    toastWarning: (message: string) => void;
    toastInfo: (message: string) => void;
} | null>(null);


// Toast provider component that wraps the tree and provides toast functions
interface ToastProviderProps {
    children: ReactNode;  // Explicitly define the children prop type
}
export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
    const toastSuccess = (message: string) => toast.success(message);
    const toastError = (message: string) => toast.error(message);
    const toastWarning = (message: string) => toast.warning(message);
    const toastInfo = (message: string) => toast.info(message);

    return (
        <ToastContext.Provider value={{ toastSuccess, toastError, toastWarning, toastInfo }}>
            {children}
        </ToastContext.Provider>
    );
};