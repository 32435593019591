import React, { useEffect, useState } from "react";
import { FormControl, TextField, Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { MdAdd } from "react-icons/md";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "antd";
import Text from "components/Text";
import SkeletonComponent from "../components/SkeletonComponent";
import { useDebounce } from "hooks/useDebounce";
import { useSubmitForm } from "../useSubmitForm";
import { fetchFieldSavedData } from "../helpers/fetchFieldSavedData";
import { useUser } from "global/UserContext";
import { useEditStatus } from "global/EditStatusContext";
import { useIsMobile, useIsTablet } from "utils/mediaQueries";
import * as I from "components/DynamicForm/types/dynamicFormTypes";
import * as S from "../styles";
const TagField: React.FC<I.BaseField> = ({
  form_id,
  label,
  is_required,
  id,
  field_type,
  description,
  extras,
}) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { user } = useUser();
  const { setEditStatus } = useEditStatus();

  const [tags, setTags] = useState<string[]>([]);
  const [newTag, setNewTag] = useState<Record<string, Record<number, string>>>(
    {},
  );
  const [placeholders, setPlaceholders] = useState<string[]>([]);
  const [isMultipleTags, setIsMultipleTags] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [prevLoading, setPrevLoading] = useState<boolean>(true);
  const [prevError, setPrevError] = useState<string | null>(null);

  const debouncedTag = useDebounce(newTag, 500);
  const { submitForm, loading: submissionLoading } = useSubmitForm();
  const user_id = user?.id;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await fetchFieldSavedData(id, user_id);

    if (data && data.length > 0) {
      const savedSubmission = data[0];

      if (savedSubmission && Array.isArray(savedSubmission.values)) {
        const savedTags = savedSubmission.values
          .map((value) => value.value)
          .filter((value): value is string => value !== undefined);
        setTags(savedTags);
      } else {
        console.warn(
          "savedSubmission.values is undefined or invalid:",
          savedSubmission,
        );
      }
    }
    setPrevLoading(false);
  };

  useEffect(() => {
    const pHold = extras?.placeholders || [];
    setPlaceholders(pHold);
    setIsMultipleTags(pHold.length > 0);
  }, [extras]);

  useEffect(() => {
    validateFields();
  }, [debouncedTag, placeholders]);

  const validateFields = () => {
    const isValid = isMultipleTags
      ? placeholders.every((_, index) => {
          const value = newTag[id]?.[index];
          return value?.trim() !== "" && value !== undefined;
        })
      : newTag[id]?.[-1]?.trim() !== "" && newTag[id]?.[-1] !== undefined;

    setIsButtonDisabled(!isValid);
  };

  const handleTagChange = (fieldId: string, index: number, value: string) => {
    setNewTag((prev: any) => ({
      ...prev,
      [fieldId]: {
        ...(prev[fieldId] || {}),
        [index]: value,
      },
    }));
    setEditStatus(true, "Edit Tag");
  };

  const handleAddNewTag = async () => {
    if (isButtonDisabled) return;

    setEditStatus(true, "Add Tag");
    let allTags = [...tags];

    if (isMultipleTags) {
      const newValue = Object.values(newTag[id]).join(" | ");
      allTags = [...allTags, newValue];
    } else {
      const newValues = Object.values(newTag[id])[0];
      const splitValues = newValues
        ? newValues
            .split(",")
            .map((val) => val.trim())
            .filter((val) => val !== "")
        : [];
      allTags = [...allTags, ...splitValues];
    }
    const submissionData = {
      values: allTags.map((t) => ({ value: t })),
    };
    await submitForm(id, submissionData);
    setTags(allTags);

    setNewTag({});
    setIsButtonDisabled(true);
  };

  const handleDelete = async (tagToDelete: string) => {
    setEditStatus(true, "Delete Tag");
    const updatedTags = tags.filter((tag) => tag !== tagToDelete);

    const submissionData = {
      values: updatedTags.map((t) => ({ value: t })),
    };

    await submitForm(id, submissionData);

    setTags(updatedTags);
  };

  const descriptionTooltip = isMultipleTags
    ? "Preencha todos os campos antes de adicionar."
    : "Preencha o campo antes de adicionar.";

  if (prevLoading) {
    return <SkeletonComponent />;
  }

  if (prevError) {
    return <div>Error loading data</div>;
  }

  return (
    <S.GridContainer isTagsLayout={true}>
      <S.CardTags key={id}>
        <div>
          <Text size="medium" bold>
            {label}
          </Text>
          {description}
        </div>
        <FormControl fullWidth>
          <S.TagsContainer>
            {isMultipleTags ? (
              placeholders.map((placeholder, index) => (
                <TextField
                  fullWidth
                  key={index}
                  label={placeholder}
                  value={newTag[id]?.[index] || ""}
                  onChange={(e) => handleTagChange(id, index, e.target.value)}
                  variant="outlined"
                  style={{
                    marginBottom: "8px",
                    width: isMobile || isTablet ? "100%" : "48%",
                  }}
                  required
                />
              ))
            ) : (
              <TextField
                fullWidth
                placeholder="Digite aqui..."
                variant="standard"
                value={newTag[id]?.[-1] || ""}
                onChange={(e) => handleTagChange(id, -1, e.target.value)}
                style={{ marginBottom: "8px" }}
                required
              />
            )}

            <Tooltip
              title={<Text size="medium">{descriptionTooltip}</Text>}
              disableHoverListener={!isButtonDisabled}
              TransitionComponent={Zoom}
              placement="left"
              arrow
            >
              <span>
                <Button
                  icon={<MdAdd size={11} className="icon-style" />}
                  type="link"
                  size="small"
                  style={S.ButtonPlusStyle}
                  onClick={handleAddNewTag}
                  disabled={isButtonDisabled}
                >
                  adicionar
                </Button>
              </span>
            </Tooltip>
          </S.TagsContainer>

          <S.TagsChipsContainer>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
              {submissionLoading ? (
                <div> Salvando...</div>
              ) : (
                tags.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    onDelete={() => handleDelete(tag)}
                    deleteIcon={<CloseIcon />}
                  />
                ))
              )}
            </Stack>
          </S.TagsChipsContainer>
        </FormControl>
      </S.CardTags>
    </S.GridContainer>
  );
};

export default TagField;
