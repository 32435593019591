import React, { useState } from "react";
import { IconButton, Box, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Text from "components/Text";
import {
  User,
  GroupRole,
  Profile,
  IndividualPlan,
  Team,
  InvitedBy,
  FormCompletion,
  Forms,
  OrganizationMember,
} from "../types/users";
import PaginationComponent from "components/Pagination/Pagination";
import theme from "styles/theme";
import MemberCard from "./MemberCard";

interface MemberListProps {
  members: OrganizationMember[];
  searchTerm: string;
  onUpdate: () => void;
}

const ITEMS_PER_PAGE = 7;

export const MemberList: React.FC<MemberListProps> = ({
  members,
  searchTerm,
  onUpdate,
}) => {
  const [sortBy, setSortBy] = useState<
    "fullName" | "group_role" | "profileName" | "individualPlanName"
  >("fullName");
  const [isAscending, setIsAscending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSortToggle = (
    column: "fullName" | "group_role" | "profileName" | "individualPlanName",
  ) => {
    if (sortBy === column) {
      setIsAscending(!isAscending);
    } else {
      setSortBy(column);
      setIsAscending(true);
    }
  };

  const filteredAndSortedMembers = members
    .map((member) => ({
      ...member,
      fullName:
        `${member.user?.first_name || ""} ${member.user?.last_name || ""}`.toLowerCase(),
      profileName: member.group_role?.name?.toLowerCase() || "",
      individualPlanName: member.individual_plan?.name?.toLowerCase() || "",
    }))
    .filter((member) => member.fullName.includes(searchTerm.toLowerCase()))
    .sort((a, b) => {
      const aValue = a[sortBy] as string;
      const bValue = b[sortBy] as string;
      if (!aValue || !bValue) return 0;
      return isAscending
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedMembers = filteredAndSortedMembers.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE,
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(
    filteredAndSortedMembers.length / ITEMS_PER_PAGE,
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: "0 8px",
          backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
          borderRadius: "4px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flex: "0.3" }}>
          <Typography variant="caption" color={theme.COLORS.TEXT_PRIMARY}>
            Nome
          </Typography>
          <IconButton onClick={() => handleSortToggle("fullName")} size="small">
            {isAscending ? (
              <ArrowUpwardIcon fontSize="small" />
            ) : (
              <ArrowDownwardIcon fontSize="small" />
            )}
          </IconButton>
        </Box>
        <Text size="small" flex={0.15} textAlign="center">
          Permissão
        </Text>
        <Text size="small" flex={0.15} textAlign="center">
          Assinatura
        </Text>
        <Text size="small" flex={0.3} textAlign="center">
          Formulário
        </Text>
        {/* <Text size="small" flex={0.15} textAlign="center">
          Último acesso
        </Text> */}
        <Text size="small" flex={0.1} textAlign="center">
          Ações
        </Text>
      </Box>

      <Box sx={{ width: "100%", marginTop: "0.5rem" }}>
        {paginatedMembers.map((member) => (
          <MemberCard
            key={member.user.id}
            member={member}
            onUpdate={onUpdate}
          />
        ))}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={2}
        mb={2}
      >
        <PaginationComponent
          totalPages={Math.ceil(
            filteredAndSortedMembers.length / ITEMS_PER_PAGE,
          )}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          siblingCount={1}
          boundaryCount={1}
          color="custom"
          customColor={theme.COLORS.BACKGROUND_SECONDARY}
        />
      </Box>
    </>
  );
};

export default MemberList;
