import { useState, useEffect } from "react";
import { fetchOrganizationMembers } from "../services/api";
import { OrganizationMember } from "../types/users";

const useOrganizationMembers = () => {
  const [members, setMembers] = useState<OrganizationMember[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const loadMembers = async () => {
    setLoading(true);
    try {
      const data = await fetchOrganizationMembers();
      setMembers(data);
    } catch (err: any) {
      setError("Erro ao carregar os membros da organização.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMembers();
  }, []);

  return { members, loading, error, loadMembers };
};

export default useOrganizationMembers;
