import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Text from "components/Text";
import theme from "styles/theme";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import { OrganizationMember } from "pages/DigitalGovernance/GovernanceUsers/types/users";
import {
  updateGroupDetails,
  linkUsersToGroup,
  unlinkUserFromGroup,
  fetchOrganizationMembers,
} from "pages/DigitalGovernance/GovernanceThematicGroups/services/api";
import { useToast } from "hooks/useToast";
import { useAvailableGroups } from "../hooks/useAvailableGroups";
import useOrganizationMembers from "pages/DigitalGovernance/GovernanceUsers/hooks/useOrganizationMembers";
import ViewGroupModal from "pages/DigitalGovernance/GovernanceThematicGroups/components/ViewGroupModal";

interface TeamGroupItemProps {
  teamName: string;
  member: OrganizationMember;
  activeUsers: number;
  position: string;
  refreshGroups: () => void;
  onUpdate: () => void;
  team: any;
}

const TeamGroupItem: React.FC<TeamGroupItemProps> = ({
  teamName,
  member,
  activeUsers,
  position,
  refreshGroups,
  onUpdate,
  team,
}) => {
  const { toastError } = useToast();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedGroupUuid, setSelectedGroupUuid] = useState<string | null>(
    null,
  );
  const [isViewGroupModalOpen, setIsViewGroupModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isConfirmUnlinkOpen, setConfirmUnlinkOpen] = useState(false);

  const handleUnlinkUser = async () => {
    if (!member || !selectedGroupUuid) return;

    try {
      await unlinkUserFromGroup(selectedGroupUuid);
      setIsSuccessOpen(true);
      setConfirmUnlinkOpen(false);
    } catch (error) {
      console.error("Erro ao desvincular usuário:", error);
      toastError("Erro ao desvincular usuário.");
    }
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    groupUuid: string,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedGroupUuid(groupUuid);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewGroup = () => {
    setIsViewGroupModalOpen(true);
    handleMenuClose();
  };

  const handleCloseViewGroupModal = () => {
    setIsViewGroupModalOpen(false);
    onUpdate();
  };

  const handleOpenUnlinkGroup = () => {
    setConfirmUnlinkOpen(true);
    handleMenuClose();
  };

  const handleCloseSuccess = () => {
    setIsSuccessOpen(false);
    refreshGroups();
    //onUpdate();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
          padding: "0.5rem",
          borderRadius: "0.5rem",
          marginBottom: "0.5rem",
          flex: 1,
        }}
      >
        <Box sx={{ flex: 0.45 }}>
          <Text size="medium">{teamName}</Text>
        </Box>

        <Box sx={{ flex: 0.15, textAlign: "center" }}>
          <Text size="medium" textAlign="center">
            {activeUsers}
          </Text>
        </Box>

        <Box sx={{ flex: 0.3, textAlign: "center" }}>
          <Text size="medium" textAlign="center">
            {position}
          </Text>
        </Box>

        <Box sx={{ flex: 0.1, textAlign: "center" }}>
          <IconButton
            onClick={(e) =>
              handleMenuOpen(
                e,
                member.teams.find((team) => team.team_name === teamName)
                  ?.team_uuid || "",
              )
            }
          >
            <MoreHorizIcon sx={{ color: theme.COLORS.PRIMARY }} />
          </IconButton>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          "& .MuiPaper-root": {
            background: theme.COLORS.BACKGROUND_PRIMARY,
            color: theme.COLORS.TEXT_PRIMARY,
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "2px",
          },
          "& .MuiMenuItem-root": {
            fontSize: "0.75rem",
            padding: "2px 4px",
            "&:hover": {
              backgroundColor: theme.COLORS.PRIMARY,
              color: theme.COLORS.BLACK,
              borderRadius: "4px",
            },
          },
        }}
      >
        <MenuItem onClick={handleViewGroup}>Visualizar grupo</MenuItem>
        <MenuItem onClick={handleOpenUnlinkGroup}>Desvincular grupo</MenuItem>
      </Menu>

      <ViewGroupModal
        open={isViewGroupModalOpen}
        onClose={handleCloseViewGroupModal}
        groupId={selectedGroupUuid || ""}
        groupName={teamName}
        initialData={team}
        onUpdate={onUpdate}
      />

      <GlobalModal
        open={isConfirmUnlinkOpen}
        title="CONFIRMAÇÃO"
        description=""
        onClose={() => setIsSuccessOpen(false)}
        onCancel={() => setConfirmUnlinkOpen(false)}
        onConfirm={handleUnlinkUser}
        cancelButtonText="Não"
        confirmButtonText="Sim"
        height="auto"
      >
        <Text>
          Deseja realmente desvincular membro "
          <strong>
            {member.user.first_name} {member.user.last_name}
          </strong>
          " do grupo temático "<strong>{teamName}</strong>"?
        </Text>
      </GlobalModal>

      <GlobalModal
        open={isSuccessOpen}
        title="CONFIRMAÇÃO"
        description="Membro desvinculado do grupo com sucesso!"
        onClose={handleCloseSuccess}
        onConfirm={handleCloseSuccess}
        confirmButtonText="Fechar"
        height="auto"
      />
    </>
  );
};

export default TeamGroupItem;
