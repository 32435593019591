import { toast } from "react-toastify";

/**
 * Handles HTTP errors and displays a friendly message to the user.
 * @param error The error object from an Axios request or similar.
 */
export const handleHttpError = (error: any): void => {
  const status = error?.response?.status;

  switch (status) {
    case 400:
      toast.error(
        error.response?.data?.message ||
          "Parece que houve um problema com a sua solicitação. Verifique os dados e tente novamente.",
      );
      break;
    case 401:
      toast.error("Você não está autenticado. Faça login e tente novamente.");
      break;
    case 403:
      toast.error(
        "Você não tem permissão para realizar essa ação. Contate o administrador.",
      );
      break;
    case 404:
      toast.error(
        "O recurso solicitado não foi encontrado. Verifique e tente novamente.",
      );
      break;
    case 409:
      toast.error(
        error.response?.data?.message ||
          "Conflito de dados. Verifique as informações e tente novamente.",
      );
      break;
    case 500:
      toast.error("Ocorreu um erro no servidor. Tente novamente mais tarde.");
      break;
    case 503:
      toast.error(
        "O servidor está temporariamente indisponível. Tente novamente mais tarde.",
      );
      break;
    default:
      toast.error("Algo deu errado. Por favor, tente novamente.");
      break;
  }
};
