import React, { useEffect, useState } from "react";
import Spin from "components/Spin";
import Text from "components/Text";

import * as S from "./styles";
import useProfileImage from "pages/SettingsProfile/hooks/useProfileImage";

import { AvatarDefault } from "utils/utilsIcons";

interface MappedMember {
  label: string;
  id: string;
}

interface InputComponentProps {
  selectedUser: MappedMember;
}

export const UserPicture: React.FC<InputComponentProps> = ({
  selectedUser,
}) => {
  const { profileImage, loading: profileImgLoading } = useProfileImage(
    selectedUser?.id || "",
  );

  const renderAvatarOrImage = () => {
    if (profileImgLoading) {
      return <Spin />;
    }
    if (profileImage) {
      return (
        <img
          src={profileImage}
          alt="profile picture"
          style={{ width: 120, height: 120, borderRadius: "50%" }}
        />
      );
    }
    return <S.ProfilePicture src={AvatarDefault} alt="profile picture" />;
  };

  if (profileImgLoading) {
    return <Spin />;
  }

  return (
    <S.ProfilePictureWrapper>
      {renderAvatarOrImage()}
      <Text size="xLarge" bold>
        {selectedUser?.label}
      </Text>
    </S.ProfilePictureWrapper>
  );
};
