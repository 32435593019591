import React from "react";
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
import { styled } from "@mui/material/styles";
import { useIsMobile, useIsTablet } from "utils/mediaQueries";
import theme from "styles/theme";

dayjs.locale("pt-br");

const StyledDayWrapper = styled("div")<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    border: `1px solid ${theme.COLORS.PRIMARY}`,
    borderColor: `${theme.COLORS.PRIMARY}`,
    color: isSelected ? theme.COLORS.BLACK : theme.COLORS.TEXT_PRIMARY,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
);

interface CalendarProps {
  selectedDate: Dayjs | null;
  onDateChange: (value: Dayjs | null) => void;
  eventDates: string[];
}

const CalendarFilter: React.FC<CalendarProps> = ({
  selectedDate,
  onDateChange,
  eventDates,
}) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isEventDate = (date: Dayjs) => {
    return eventDates.includes(date.format("YYYY-MM-DD"));
  };

  const CustomDay = (props: any) => {
    const { day, selected, outsideCurrentMonth, ...other } = props;
    const isEvent = isEventDate(day);
    const formattedDay = day.format("YYYY-MM-DD");

    return (
      <PickersDay
        {...other}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
        sx={{
          borderRadius: "50%",
          "&.Mui-selected": {
            background: theme.COLORS.PRIMARY,
            color: theme.COLORS.BLACK,
            "&:hover": {
              background: theme.COLORS.PRIMARY,
            },
          },
          "&.Mui-selected.MuiPickersDay-today": {
            borderColor: theme.COLORS.PRIMARY,
          },
          ...(isEvent && {
            border: `1px solid ${theme.COLORS.PRIMARY}`,
          }),
          color: selected ? theme.COLORS.BLACK : theme.COLORS.TEXT_PRIMARY,
          background: selected
            ? theme.COLORS.PRIMARY
            : theme.COLORS.BACKGROUND_PRIMARY,

          "&:hover": {
            background: theme.COLORS.BACKGROUND_SECONDARY,
          },
        }}
      >
        {isEvent ? (
          <StyledDayWrapper isSelected={selected}>
            {day.date()}
          </StyledDayWrapper>
        ) : (
          day.date()
        )}
      </PickersDay>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <DateCalendar
        value={selectedDate}
        onChange={onDateChange}
        disablePast
        slots={{
          day: CustomDay,
        }}
        sx={{
          transform: isMobile
            ? "scale(1)"
            : isTablet
              ? "scale(1)"
              : "scale(1.1)",
          background: theme.COLORS.BACKGROUND_PRIMARY,
          "& .MuiPickersArrowSwitcher-button": {
            color: `${theme.COLORS.PRIMARY} !important`,
          },
          "& .MuiSvgIcon-root": {
            color: `${theme.COLORS.PRIMARY} !important`,
          },
          "& .MuiDayCalendar-weekDayLabel": {
            color: `${theme.COLORS.TEXT_PRIMARY} !important`,
            fontWeight: "bold",
            fontSize: isMobile ? "0.8rem" : "1rem",
          },
          "& .MuiPickersCalendarHeader-label": {
            color: `${theme.COLORS.TEXT_PRIMARY} !important`,
          },
          "& .MuiPickersYear-root": {
            color: `${theme.COLORS.PRIMARY} !important`,
          },
          "& .MuiPickersMonth-root": {
            color: `${theme.COLORS.PRIMARY} !important`,
          },
          "& .Mui-selected": {
            background: `${theme.COLORS.PRIMARY} !important`,
            color: theme.COLORS.BLACK,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CalendarFilter;
