import React, { useEffect } from "react";
import ImageCarousel from "pages/Cockpit/components/ImagesCarousel";
import Agenda from "pages/Cockpit/components/Agenda";
import MobileCockpit from "./MobileCockpit";
import { useTab } from "global/TabContext";
import { useMenuTitle } from "hooks/useMenuTitleContext";
import { useEvents } from "pages/Cockpit/components/Agenda/hooks/useEvents";
import { useIsMobile, useIsTablet } from "utils/mediaQueries";
import * as S from "./styles";

export const Cockpit: React.FC = () => {
  const { events, eventsToday, loading, error } = useEvents();
  const [activeTab] = React.useState("tab_cockpit");
  const { setActiveTabContext } = useTab();
  const { updateMenuTitle } = useMenuTitle();

  useEffect(() => {
    setActiveTabContext(activeTab);
    updateMenuTitle("Cockpit");
  }, [activeTab, setActiveTabContext]);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return isMobile || isTablet ? (
    <S.MobileSection>
      <MobileCockpit events={eventsToday} />
    </S.MobileSection>
  ) : (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.Section>
            <ImageCarousel />
          </S.Section>
          <S.Section>
            <Agenda />
          </S.Section>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
};
