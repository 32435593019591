import React, { useEffect, useState } from "react";
import UserProfileCard from "components/UserProfileCard";
import TabsCustom from "components/Tabs";
import Spin from "components/Spin";
import { useTab } from "global/TabContext";
import { useCandidates } from "hooks/useCandidates";
import SelfAssessment from "pages/SelfAssessment";
import Challenges2025 from "pages/UserProfile/Challenges2025";
import FeedbackNetorkingExp from "./FeedbackNetworkingExp";
import InvitesPage from "pages/UserProfile/Invites";
import { useIsMobile, useIsTablet } from "utils/mediaQueries";
import { UnderConstruction } from "components/UnderConstruction";
import useProfileCompletion from "hooks/useProfileCompletion";
import { useUser } from "global/UserContext";
import * as S from "./styles";

const content: React.ReactNode[] = [
  <SelfAssessment />,
  <Challenges2025 />,
  <FeedbackNetorkingExp />,
  <InvitesPage />,
];

export const UserProfile = () => {
  const { user } = useUser();
  const [activeTab, setActiveTab] = useState(
    "tab_user_profile_self_assessment",
  );
  const [labels, setLabels] = useState([
    {
      label: "Auto Avaliação",
      sub_label: "Networking",
      key: "tab_user_profile_self_assessment",
      disabled: false,
    },
    {
      label: "Desafios e Projetos",
      sub_label: "2025",
      key: "tab_user_profile_challeges_2025",
      disabled: false,
    },
    {
      label: "Feedback Networking Experience",
      sub_label: "",
      key: "tab_user_feedback_neworking_exp",
      disabled: false,
    },
    {
      label: "Indicações",
      sub_label: "",
      key: "tab_user_indications",
      disabled: false,
    },
  ]);

  const { isLoadedCandidates } = useCandidates();
  const { setActiveTabContext } = useTab();
  const { fetchCompletion } = useProfileCompletion();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  const userId = user?.id ? user.id : "";

  useEffect(() => {
    const updateLabels = async () => {
      const completion = await fetchCompletion(userId);

      setLabels((prevLabels) =>
        prevLabels.map((label) => ({
          ...label,
          /*  disabled:
            label.key !== "tab_user_profile_self_assessment" &&
            label.key !== "tab_user_indications" &&
            (completion ?? 0) < 100, */
        })),
      );
    };

    updateLabels();
  }, [fetchCompletion, userId]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changePoliticalTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return isMobile || isTablet ? (
    <UnderConstruction />
  ) : (
    <S.Wrapper>
      <UserProfileCard onClose={() => {}} />
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={content.map((comp) =>
            React.cloneElement(comp as React.ReactElement),
          )}
          onChange={(key) => changePoliticalTab(key)}
        />
      </S.Content>
      <S.Separator></S.Separator>
    </S.Wrapper>
  );
};
