import styled from "styled-components";
import theme from "styles/theme";

export const AgendaContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  background: ${theme.COLORS.BACKGROUND_SECONDARY};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

  .MuiAccordion-root {
    background: ${theme.COLORS.BACKGROUND_SECONDARY};
    color: ${theme.COLORS.TEXT_PRIMARY};
    border-radius: 8px;

    &:before {
      display: none;
    }
  }

  .MuiAccordionSummary-root {
    padding: 0 16px;
    min-height: 48px;

    .MuiAccordionSummary-content {
      margin: 0;
      align-items: center;
    }

    .MuiSvgIcon-root {
      font-size: 1.5rem;
    }
  }

  .MuiAccordionDetails-root {
    padding: 16px;
  }
`;
