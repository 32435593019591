import { useState, useEffect, useCallback } from "react";
import { apiOrchestrator } from "services/apiService";
import { useToast } from "hooks/useToast";
import { Group, GroupMember } from "../types/groups";

interface User {
  id: string;
  first_name: string;
  last_name: string;
}

interface UseUnassignedUsersResult {
  groups: Group[];
  unassignedUsers: User[];
  loading: boolean;
  error: string | null;
}

const useUnassignedUsers = (): UseUnassignedUsersResult => {
  const { toastError } = useToast();
  const [groups, setGroups] = useState<Group[]>([]);
  const [unassignedUsers, setUnassignedUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchGroupsAndUsers = useCallback(async () => {
    try {
      setLoading(true);

      const [groupRes, userRes] = await Promise.all([
        apiOrchestrator.get("/team/all"),
        apiOrchestrator.get("/user_to_organization/members"),
      ]);

      const groupData: Group[] = groupRes.data.map((group: any) => ({
        uuid: group.uuid,
        name: group.name,
        members: group.members.map((member: any) => ({
          id: member.uuid,
          first_name: member.first_name,
          last_name: member.last_name,
        })),
      }));

      const userData: User[] = userRes.data.map((user: any) => ({
        id: user.user.id,
        first_name: user.user.first_name,
        last_name: user.user.last_name,
      }));

      setGroups(groupData);

      const groupMemberIds = new Set(
        groupData.flatMap((group) => group.members.map((member) => member.id)),
      );

      const ungroupedUsers = userData.filter(
        (user) => !groupMemberIds.has(user.id),
      );
      setUnassignedUsers(ungroupedUsers);

      setError(null);
    } catch (err) {
      console.error(err);
      setError("Erro ao buscar dados de grupos ou usuários.");
      toastError("Erro ao buscar dados de grupos ou usuários.");
    } finally {
      setLoading(false);
    }
  }, [toastError]);

  useEffect(() => {
    fetchGroupsAndUsers();
  }, [fetchGroupsAndUsers]);

  return { groups, unassignedUsers, loading, error };
};

export default useUnassignedUsers;
