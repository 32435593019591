import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { AvatarDefault } from "utils/utilsIcons";
import { PoweredByAlgoricca } from "utils/utilsIcons";
import Avatars from "components/Avatars";
import ModalSubMenu from "components/ModalSubMenu";
import Text from "components/Text";
import Button from "components/Button";
import ButtonSibBar from "components/ButtonSidebar";
import ConfirmLogoutModal from "components/Logout/components/ConfirmLogoutModal";
import { useSideBar } from "hooks/useSideBar";
import { useUser } from "global/UserContext";
import { useDrawerState } from "global/DrawerContext";
import {
  generateSidebarMenuFromFeatures,
  SidebarMenuItem,
} from "utils/sidebarUtils";
import usePingOnline from "microfrontends/hooks/usePingOnline";
import useUserImage from "hooks/useUserImage";
import { formatUserName } from "utils/formatUserName";
import * as S from "./styles";

const SideBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { open } = useSideBar();
  const { user, features, permissions, logout, token } = useUser();
  const { setActivePlanFeatures } = useDrawerState();

  const [menu, setMenu] = useState<SidebarMenuItem[]>([]);
  const [activeSubMenu, setActiveSubMenu] = useState<SidebarMenuItem | null>(
    null,
  );
  const { userImage, loadingImage } = useUserImage(user?.id || "");

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const handleOpenLogoutModal = () => setLogoutModalOpen(true);
  const handleCloseLogoutModal = () => setLogoutModalOpen(false);

  useEffect(() => {
    if (features.length > 0) {
      const generatedMenu = generateSidebarMenuFromFeatures(
        features,
        permissions,
      );
      setMenu(generatedMenu);
    }
  }, [features, permissions]);

  useEffect(() => {
    const matchingMenu = menu.find((item) =>
      item.subMenu?.some((sub) => sub.path && pathname.startsWith(sub.path)),
    );
    setActiveSubMenu(matchingMenu || null);
    setActivePlanFeatures(features);
  }, [pathname, menu, features]);

  usePingOnline();

  const renderAvatar = useCallback(() => {
    if (loadingImage) {
      return <Skeleton variant="circular" width={56} height={56} />;
    }
    return (
      <Avatars
        img={userImage || AvatarDefault}
        size={{ width: open ? 56 : 32, height: open ? 56 : 32 }}
      />
    );
  }, [open, userImage, loadingImage]);

  const handleConfirmLogout = useCallback(() => {
    setLogoutModalOpen(false);
    setTimeout(() => {
      logout();
    }, 300);
  }, [logout]);

  return (
    <S.Container open={open}>
      <S.WrapperMenu>
        <div className="avatar">
          {renderAvatar()}
          {open && (
            <div style={{ marginTop: "0.5rem" }}>
              <Text size="small" bold className="user-name">
                Bem vindo,
              </Text>
              <Text size="small" bold className="user-name">
                {formatUserName(`${user?.first_name} ${user?.last_name}`)}
              </Text>
            </div>
          )}
        </div>

        {menu.map((menuItem) => (
          <ButtonSibBar
            label={menuItem.label || ""}
            path={menuItem.path || "#"}
            title={menuItem.title}
            subtitle={menuItem.subtitle || ""}
            icon={menuItem.icon}
            isActive={menuItem.isActive}
            subMenu={menuItem.subMenu || []}
            key={menuItem.title}
            disabled={!menuItem.isActive}
            onClick={() => {
              if (menuItem.subMenu?.length) {
                setActiveSubMenu(menuItem);
              } else {
                setActiveSubMenu(null);
                navigate(menuItem.path || "#");
              }
            }}
          />
        ))}
      </S.WrapperMenu>

      {activeSubMenu && (
        <ModalSubMenu
          subMenu={activeSubMenu.subMenu || []}
          title={activeSubMenu.title}
          closeSubMenu={() => setActiveSubMenu(null)}
          onSelectSubMenu={(path) => {
            setActiveSubMenu(null);
            navigate(path);
          }}
          open={!!activeSubMenu}
          isSubMenuActive={
            !!activeSubMenu.subMenu?.find((menu) => menu.isActive)
          }
        />
      )}

      <S.WrapperFooter>
        {open && (
          <>
            <S.Logo>
              <img src={PoweredByAlgoricca} alt="Powered By Algoricca" />
            </S.Logo>

            <div className="plan">
              {user?.user_to_organization &&
              user?.user_to_organization.length > 0 ? (
                user?.user_to_organization.map((org) => (
                  <Text size="small" key={org.organization.uuid}>
                    Plano: <b>{org.organization?.plan?.name || "N/A"}</b>
                  </Text>
                ))
              ) : (
                <Text size="small">Nenhum plano associado</Text>
              )}
            </div>
          </>
        )}

        <Button onClick={handleOpenLogoutModal} variant="minimal" size="small">
          Sair
        </Button>
      </S.WrapperFooter>

      <ConfirmLogoutModal
        open={logoutModalOpen}
        onClose={handleCloseLogoutModal}
        onConfirm={handleConfirmLogout}
      />
    </S.Container>
  );
};

export default SideBar;
