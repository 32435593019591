import React, { useEffect, useState } from "react";
import Spin from "components/Spin";
import { ButtonGroup, Divider, IconButton, Paper, Rating, Stack, Avatar } from "@mui/material";
import useProfileImage from "pages/SettingsProfile/hooks/useProfileImage";


import Text from "components/Text";
import { apiNetworking, apiOrchestrator } from "services/apiService";

import { useUser } from "global/UserContext";

import SkeletonAvatar from "antd/es/skeleton/Avatar";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

import * as S from "./styles"
import theme from "styles/theme";
import { fontSize } from "@mui/system";


interface MappedMember {
    label: string;
    id: string;
}

interface EdgeTypeTotals {
    CONNECTED?: number;
    SCHEDULED?: number;
    WHATSAPP?: number;
}

interface UserStats {
    ranking: number;
    total_points: number;
    edge_type_totals: EdgeTypeTotals;
    nps: number;
}

interface CommonEdge {
    type_code: string;
    from_user_uuid: string;
    to_user_uuid: string;
}

interface PendingConnectionsProps {
    setSelectedUser: (user: MappedMember | null) => void;
}

export const PendingConnections: React.FC<PendingConnectionsProps> = ({ setSelectedUser }) => {
    const { user } = useUser();
    const [loading, setLoading] = useState<boolean>(true);
    const [pendingConnections, setPendingConnections] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchData();
    }, [user]);

    const fetchData = async () => {
        setLoading(true);
        setError(null);

        try {
            const [fromEdges, toEdges] = await Promise.all([
                apiNetworking.get(`/v1/edge/find?from_user_uuid=${user?.id}&type_code=CONNECTION_LEVEL`),
                apiNetworking.get(`/v1/edge/find?to_user_uuid=${user?.id}&type_code=CONNECTION_LEVEL`),
            ]);

            const toUserSources = toEdges.data.map((e: CommonEdge) => e.from_user_uuid) // quem quis conectar com user
            const fromUserTargets = fromEdges.data.map((e: CommonEdge) => e.to_user_uuid) // quem o user quis conectar

            // Use a Set for efficient lookup
            const fromUserTargetsSet = new Set(fromUserTargets);

            // Filter the toUserSources array to exclude elements in fromUserTargets
            const uniqueToUserSources = toUserSources.filter((id: string) => !fromUserTargetsSet.has(id));


            // console.log({ toUserSources, fromUserTargets, fromUserTargetsSet, uniqueToUserSources })
            setPendingConnections(uniqueToUserSources);

        } catch (err) {
            console.error("Error fetching data:", err);
            setError("Failed to load data. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin />;
    }

    if (error) {
        return <Paper>{error}</Paper>;
    }


    return (
        <Paper>
            <Text>Conexões pendentes</Text>
            {pendingConnections?.length === 0 && <Text color="gray">Não há solicitações de conexões pendentes.</Text>}
            {pendingConnections?.map(p =>
                <Paper
                    aria-label={`KPI: ${p}`}
                    sx={{
                        backgroundColor: "#1E1E1E",
                        color: "#FFD700",
                        borderRadius: 2,
                        p: 2,
                        textAlign: "left",
                        minWidth: "100px",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                    }}>
                    <PendingConnCard userIdPending={p} onClick={setSelectedUser} />
                </Paper>)
            }
        </Paper>
    );
};

interface PendingConnCardProps {
    userIdPending: string;
    onClick: (selectedUser: MappedMember | null) => void
}

const PendingConnCard: React.FC<PendingConnCardProps> = ({ userIdPending, onClick }) => {
    const [userData, setUserData] = useState<MappedMember | null>()
    const [isLoading, setIsloading] = useState<boolean>(true)
    const { profileImage, loading: profileImgLoading } = useProfileImage(userData?.id || "");

    useEffect(() => { fetchData() }, [userIdPending])

    const fetchData = async () => {
        setIsloading(true);
        try {
            const response = await apiOrchestrator.get(`/users-person/${userIdPending}`);

            setUserData({ id: response.data?.id, label: `${response.data?.first_name} ${response.data?.last_name}` });

        } catch (err) {
            console.error("Error fetching data:", err);
            // setError("Failed to load data. Please try again later.");
        } finally {
            setIsloading(false);
        }
    };


    if (isLoading) {
        return <Spin />;
    }
    else {
        return (
            <Stack direction={"row"} gap={1} sx={{ minWidth: "200px" }} >
                {profileImgLoading ? <SkeletonAvatar active={true} /> : <Avatar src={profileImage || ""} />}
                <div style={{ width: "100%" }}>
                    <Text bold size="medium">{userData?.label}</Text>
                    <IconButton
                        // aria-label={ad} 
                        size="small"
                        onClick={() => onClick(userData || null)}
                        sx={{ float: "right" }}>
                        <LibraryAddIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                </div>
            </ Stack >
        );
    }

};

