import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { apiOrchestrator } from "services/apiService";
import Button from "components/Button";
import { useToast } from "hooks/useToast";
import { useUser } from "global/UserContext";
import Spin from "components/Spin";
import Text from "components/Text";
import theme from "styles/theme";
import { useIsMobile, useIsTablet, useIsDesktop } from "utils/mediaQueries";
import * as S from "./styles";

const confirmForgotPasswordSchema = yup.object().shape({
  confirmationCode: yup
    .string()
    .matches(/^[0-9]{6}$/, "O código deve conter exatamente 6 dígitos")
    .required("Código de confirmação é obrigatório"),
  newPassword: yup
    .string()
    .required("Nova senha é obrigatória")
    .min(8, "A senha deve ter no mínimo 8 caracteres")
    .matches(/[A-Z]/, "A senha deve ter pelo menos uma letra maiúscula")
    .matches(/[a-z]/, "A senha deve ter pelo menos uma letra minúscula")
    .matches(/\d/, "A senha deve ter pelo menos um número")
    .matches(/[\W_]/, "A senha deve ter pelo menos um caractere especial"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "As senhas devem coincidir")
    .required("Confirmação de senha é obrigatória"),
});

interface ConfirmForgotPasswordModalProps {
  open: boolean;
  onClose: () => void;
  email: string;
}

const ConfirmForgotPasswordModal: React.FC<ConfirmForgotPasswordModalProps> = ({
  open,
  onClose,
  email,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(confirmForgotPasswordSchema),
  });
  const { toastSuccess, toastError } = useToast();
  const { setToken, setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const onSubmit = async (data: {
    confirmationCode: string;
    newPassword: string;
  }) => {
    setLoading(true);
    try {
      const response = await apiOrchestrator.post(
        "/auth/confirm_forgot_password",
        {
          email,
          password: data.newPassword,
          confirmation_code: data.confirmationCode,
        },
      );

      if (response.data.status === "success") {
        const { access_token, user_id } = response.data;
        setToken(access_token);

        const userResponse = await apiOrchestrator.get(`/auth/me/${user_id}`);
        setUser(userResponse.data);

        toastSuccess("Senha redefinida com sucesso!");
        onClose();
        navigate("/dashboard/cockpit");
      } else {
        toastError(
          "Erro ao redefinir a senha. Verifique o código e tente novamente.",
        );
      }
    } catch (error) {
      toastError("Erro ao redefinir a senha. Por favor, tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    onClose();
  };

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <div
        style={{
          width: "400px",
          backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
          padding: "2rem",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <h2 style={{ color: theme.COLORS.TEXT_PRIMARY }}>
          CONFIRMAR CÓDIGO E NOVA SENHA
        </h2>

        <S.Content>
          <Text size="medium" className="description">
            Entre com o código recebido em seu e-mail e com uma nova senha.
          </Text>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="confirmationCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Código de Confirmação"
                  fullWidth
                  error={!!errors.confirmationCode}
                  helperText={errors.confirmationCode?.message}
                  style={{ marginBottom: "1rem" }}
                  inputProps={{
                    maxLength: 6,
                    inputMode: "numeric",
                  }}
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    input.value = input.value.replace(/\D/g, "");
                  }}
                  size={isMobile ? "small" : "medium"}
                />
              )}
            />

            <Controller
              name="newPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nova Senha"
                  type={showNewPassword ? "text" : "password"}
                  fullWidth
                  error={!!errors.newPassword}
                  helperText={errors.newPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginBottom: "1rem" }}
                  size={isMobile ? "small" : "medium"}
                />
              )}
            />

            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Confirme a Nova Senha"
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginBottom: "1rem" }}
                  size={isMobile ? "small" : "medium"}
                />
              )}
            />

            <Button
              type="submit"
              variant="primary"
              fullWidth
              disabled={loading}
              style={{
                backgroundColor: theme.COLORS.PRIMARY,
                color: theme.COLORS.BLACK,
                marginTop: "1rem",
                padding: "0.75rem 0",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              {loading ? <Spin size="small" /> : "Confirmar e Redefinir Senha"}
            </Button>
          </form>
        </S.Content>
      </div>
    </Modal>
  );
};

export default ConfirmForgotPasswordModal;
