import { createTheme } from "@mui/material/styles";
import theme from "./theme";

const MaterialTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: theme.COLORS.PRIMARY,
    },
    secondary: {
      main: theme.COLORS.GRAY_10,
    },
    text: {
      primary: theme.COLORS.TEXT_PRIMARY,
      secondary: theme.COLORS.TEXT_SECONDARY,
    },
    background: {
      default: theme.COLORS.BACKGROUND_PRIMARY,
      paper: theme.COLORS.BACKGROUND_SECONDARY,
    },
  },
  typography: {
    allVariants: {
      color: theme.COLORS.TEXT_PRIMARY,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: theme.COLORS.GRAY_10,
        },
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.COLORS.GRAY_10,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.COLORS.GRAY_10,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: theme.COLORS.TEXT_PRIMARY,
          "&.Mui-selected": {
            color: theme.COLORS.PRIMARY,
          },
        },
      },
    },
  },
});

export default MaterialTheme;
