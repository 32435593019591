"use client";

import React from "react";
import { useUser } from "global/UserContext";
import { useIframeCommunication } from "hooks/useIframeCommunication";
import { apiAdvisorsNext } from "microfrontends/config/api";

const AdvisorsAccessHub: React.FC = () => {
  const { user } = useUser();
  const userId = user?.id;
  const iframeUrl = `${apiAdvisorsNext}/my-portfolio?userId=${userId}`;

  const { iframeRef } = useIframeCommunication(iframeUrl);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <iframe
        ref={iframeRef}
        src={iframeUrl}
        style={{
          flex: 1,
          minHeight: "1000px",
          border: "none",
        }}
        width="100%"
        title="Access Hub"
      />
    </div>
  );
};

export default AdvisorsAccessHub;
