import React from "react";
import { useFetchForms } from "hooks/useFetchForms";
import DynamicForm from "components/DynamicForm";
import Text from "components/Text";
import Spin from "components/Spin";
import PersonalInfoForm from "components/PersonalInfoForm";
import TermComponent from "components/Term";
import TitleFormComponent from "components/TitleForm";
import { DataProtectionTerm } from "./utils/dataProtectionTerm";
import { useUser } from "global/UserContext";
import { useSocialNetworks } from "components/SocialNetworkField/hooks/useSocialNetworks";
import SocialNetworkFields from "./components/SocialNetworkFields";
import * as S from "./styles";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import { useModalContext } from "global/ModalContext";
import { useNavigate } from "react-router-dom";

const SelfAssessment: React.FC = () => {
  const { user } = useUser();
  const {
    forms,
    loading: formsLoading,
    error,
  } = useFetchForms("9e038ecb-1542-4e90-acbd-91c537102bc3");

  const { networkTypes, loading: networksLoading } = useSocialNetworks();

  const { title, subtitle, description, content, version } = DataProtectionTerm;
  const uuid = user?.id;

  const loading = formsLoading || networksLoading;

  if (loading) return <Spin fullscreen />;
  if (error)
    return (
      <Text color="red" bold>
        Erro ao carregar formulário
      </Text>
    );

  const { showModal, setShowModal } = useModalContext();
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <S.Container>
        <TermComponent
          title={title}
          subtitle={subtitle}
          description={description}
          content={content}
          version={version}
        />
        <S.PersonalDataContainer>
          <TitleFormComponent title="Dados Pessoais" />
          <PersonalInfoForm />
          <SocialNetworkFields networkTypes={networkTypes} />
        </S.PersonalDataContainer>
        {forms.map((form) => (
          <S.Container key={form.id}>
            <TitleFormComponent title={form?.form_name} />
            <DynamicForm form_id={form.id} uuid={uuid} />
          </S.Container>
        ))}
      </S.Container>

      <GlobalModal
        open={showModal && !loading}
        title="INFORMAÇÃO"
        description="Finalize as informações do seu perfil para acessar todos os recursos do sistema."
        onClose={handleClose}
        confirmButtonText="Preencher formulário"
        width="500px"
        height="300px"
      />
    </>
  );
};

export default SelfAssessment;
