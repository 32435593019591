import React, { useEffect, useState } from "react";
import { TextField, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Text from "components/Text";
import Spin from "components/Spin";
import InternalButton from "components/InternalButton";
import CreateEventModal from "./components/CreateEventModal";
import EventList from "./components/EventList";
import { apiRainmakers } from "services/apiService";
import { Event } from "./types/events";
import theme from "styles/theme";
import * as S from "./styles";

const GovernanceEvents: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const response = await apiRainmakers.get("/events/v1/events/all");
      if (Array.isArray(response.data)) {
        setEvents(response.data);
      } else {
        console.error("Dados inesperados:", response.data);
      }
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredEvents = events.filter((event) =>
    event.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        my: 0,
        padding: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "0.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "0.4",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Text size="large">Eventos Onlines ({filteredEvents.length})</Text>
          </Box>
          <TextField
            variant="outlined"
            placeholder="Buscar por Eventos"
            value={searchTerm}
            onChange={handleSearchChange}
            size="small"
            fullWidth
            sx={S.SearchService}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "0.75rem",
            flex: "0.6",
            justifyContent: "flex-end",
          }}
        >
          <InternalButton
            size="large"
            background={theme.COLORS.PRIMARY}
            textColor={theme.COLORS.BLACK}
            variant="primary"
            iconPosition="left"
            icon={<AddIcon sx={{ fontSize: "auto" }} />}
            onClick={() => setOpenModal(true)}
          >
            Criar novo evento
          </InternalButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          flexDirection: "column",
          minHeight: "300px",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <Spin />
          </Box>
        ) : filteredEvents.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <Text color="gray">
              {searchTerm
                ? "Nenhum evento encontrado com o termo buscado."
                : "Nenhum evento cadastrado ainda."}
            </Text>{" "}
          </Box>
        ) : (
          <EventList
            events={filteredEvents}
            searchTerm={searchTerm}
            onUpdate={fetchEvents}
            loading={loading}
          />
        )}

        <CreateEventModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          onEventCreated={(eventId) => {}}
          onUpdate={fetchEvents}
        />
      </Box>
    </Box>
  );
};

export default GovernanceEvents;
