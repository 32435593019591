import React from "react";
import { Box, Tooltip } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import theme from "styles/theme";

const CustomTooltip = () => {
  return (
    <Box
      display="flex"
      alignItems="top"
      sx={{
        backgroundColor: "transparent",
        padding: 0,
      }}
    >
      <Tooltip
        title={
          <Box
            sx={{
              background: theme.COLORS.BACKGROUND_PRIMARY,
              padding: 0.5,
              borderRadius: 0.5,
              color: theme.COLORS.TEXT_PRIMARY,
              fontFamily: "Roboto",
              fontSize: theme.FONT_SIZE.XS,
              fontStyle: "normal",
              fontWeight: theme.FONT_WEIGHT.REGULAR,
              lineHeight: theme.FONT_SIZE.SM,
              maxWidth: "20rem",
            }}
          >
            <ul style={{ paddingLeft: "1rem", margin: 0 }}>
              <li>
                <strong>Quando selecionado um grupo</strong>, membros do grupo
                serão atualizados automaticamente no evento. Novos participantes
                entram e os removidos deixam as próximas recorrências.
              </li>
              <li>
                <strong>Quando selecionado uma pessoa</strong>, esses serão
                participantes fixos do evento até serem manualmente excluídos.
              </li>
            </ul>
          </Box>
        }
        placement="right"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
              color: theme.COLORS.TEXT_PRIMARY,
              boxShadow: "none",
              padding: "8px",
              borderRadius: "4px",
            },
          },
          arrow: {
            sx: {
              color: theme.COLORS.BACKGROUND_PRIMARY,
            },
          },
        }}
      >
        <QuestionMarkIcon
          sx={{
            color: theme.COLORS.PRIMARY,
            fontSize: theme.FONT_SIZE.XXS,
            cursor: "pointer",
          }}
        />
      </Tooltip>
    </Box>
  );
};

export default CustomTooltip;
