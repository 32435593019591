import { useCallback, useEffect, useState } from "react";
import { Group } from "pages/DigitalGovernance/GovernanceThematicGroups/types/groups";
import { apiOrchestrator } from "services/apiService";

export interface Team {
  uuid: string;
  name: string;
  description: string;
  activeDate: string;
  members: Array<{
    uuid: string;
    first_name: string;
    last_name: string;
    position: string;
    user_to_team_uuid: string;
  }>;
}

export const useAvailableGroups = (userUuid: string) => {
  const [availableGroups, setAvailableGroups] = useState<Group[]>([]);
  const [unavailableGroups, setUnavailableGroups] = useState<Group[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchGroups = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiOrchestrator.get("/team/all");
      const allGroups: Team[] = response.data;

      const available: Group[] = [];
      const unavailable: Group[] = [];

      allGroups.forEach((team) => {
        const isMember = team.members.some(
          (member) => member.uuid === userUuid,
        );
        const group: Group = {
          uuid: team.uuid,
          name: team.name,
          description: team.description || "Descrição não disponível",
          activeDate: team.activeDate || "Data não disponível",
          members: team.members.map((member) => ({
            id: member.uuid,
            uuid: member.uuid,
            first_name: member.first_name,
            last_name: member.last_name,
            position: member.position,
            date: "N/A",
          })),
        };

        if (isMember) {
          unavailable.push(group);
        } else {
          available.push(group);
        }
      });

      setAvailableGroups(available);
      setUnavailableGroups(unavailable);
    } catch (err: any) {
      setError(err.message || "Erro ao buscar grupos.");
    } finally {
      setLoading(false);
    }
  }, [userUuid]);

  useEffect(() => {
    if (userUuid) {
      fetchGroups();
    }
  }, [userUuid, fetchGroups]);

  return {
    availableGroups,
    unavailableGroups,
    loading,
    error,
    refreshGroups: fetchGroups,
  };
};
