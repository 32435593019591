import { useEffect, useRef, useState } from "react";

const useHasTextOverflow = (text: string) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      const isOverflowing = element.scrollWidth > element.clientWidth;
      setHasOverflow(isOverflowing);
    }
  }, [text]);

  return { ref, hasOverflow };
};

export default useHasTextOverflow;
