import React from "react";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import Text from "components/Text";
import { Box } from "@mui/material";

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  event: {
    title: string;
    location: string;
    date: string;
    time: string;
  };
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  event,
}) => {
  return (
    <GlobalModal
      open={open}
      title="CONFIRMAÇÃO"
      description={`Você deseja se inscrever no evento:`}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmButtonText="Confirmar"
      cancelButtonText="Cancelar"
      onCancel={onClose}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: 0.5,
        }}
      >
        <Text size="medium" bold>
          {event.title}
        </Text>
        <Text size="medium" bold>
          {event.location}
        </Text>
        <Text size="medium" color="primary" bold>
          Data: {event.date} - {event.time}
        </Text>
      </Box>
    </GlobalModal>
  );
};

export default ConfirmationModal;
