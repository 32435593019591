import { useMemo } from "react";
import { OrganizationMember } from "../types/users";

export const useFilterOptions = (members: OrganizationMember[]) => {
  const permissions = useMemo(() => {
    const map = new Map(
      members.map((member) => [
        member.group_role?.uuid || "N/A",
        member.group_role?.name || "Sem Permissão",
      ]),
    );
    return Array.from(map.entries());
  }, [members]);

  const plans = useMemo(() => {
    const map = new Map(
      members.map((member) => [
        member.individual_plan?.uuid || "N/A",
        member.individual_plan?.name || "Sem Plano",
      ]),
    );
    return Array.from(map.entries());
  }, [members]);

  const groups = useMemo(() => {
    const map = new Map(
      members.flatMap((member) =>
        (member.teams || []).map((team) => [team.team_uuid, team.team_name]),
      ),
    );
    return Array.from(map.entries());
  }, [members]);

  const invitedBy = useMemo(() => {
    const map = new Map(
      members.map((member) => [
        member.invited_by?.user_uuid || "N/A",
        `${member.invited_by?.first_name || "N/A"} ${
          member.invited_by?.last_name || ""
        }`.trim(),
      ]),
    );
    return Array.from(map.entries());
  }, [members]);

  return { permissions, plans, groups, invitedBy };
};
