import styled from "styled-components";
import theme from "styles/theme";
import "styles/global";

export const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  color: ${theme.COLORS.GRAY_10};
`;

export const Content = styled.div`
  width: 100%;

  @media screen and (min-width: 1190px) {
    max-width: 1200px;
    min-width: 1024px;
  }
`;

export const ImgContent = styled.div`
  height: 80%;
  justify-items: end;
  display: flex;
  align-items: end;
  float: right;
`;

export const SearchService = {
  height: "1.5rem",
  "& .MuiInputBase-root": {
    minHeight: "1.5rem",
    padding: "2px",
  },
  "& .MuiInputBase-input": {
    height: "1.5rem",
    padding: "0 8px",
    fontSize: "0.8rem",
  },
};
