import { saveAs } from "file-saver";
import {
  Forms,
  GroupRole,
  IndividualPlan,
  InvitedBy,
  Profile,
  Team,
  User,
} from "pages/DigitalGovernance/GovernanceUsers/types/users";

export interface OrganizationMember {
  uuid: string;
  user: User;
  group_role: GroupRole;
  profile: Profile;
  individual_plan: IndividualPlan;
  teams: Team[];
  invited_by: InvitedBy;
  forms: Forms;
  email: string;
  whatsapp_number: string;
}
export const exportToCSV = (
  data: OrganizationMember[],
  filename: string,
  onDownloadSuccess?: () => void,
) => {
  const csvContent =
    "data:text/csv;charset=utf-8," +
    [
      [
        "Nome",
        "Permissão",
        "Plano",
        "Preenchimento Médio dos Formulários",
        "E-mail",
        "WhatsApp",
      ],
      ...data.map((member) => [
        `${member.user?.first_name} ${member.user?.last_name}`,
        member.group_role?.name || "N/A",
        member.individual_plan?.name || "N/A",
        member.forms?.overall_completion || "N/A",
        member?.email || "N/A",
        member?.whatsapp_number || "N/A",
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, `${filename}.csv`);

  if (onDownloadSuccess) {
    onDownloadSuccess();
  }
};
