import { useState, useEffect } from "react";
import { apiOrchestrator } from "services/apiService";

export interface MemberData {
  first_name: string;
  last_name: string;
  whatsapp_number: string;
  updated_at: string;
  is_advisor: boolean;
  id: string;
  email: string;
  created_at: string;
  person_id: string;
}

export const useMemberData = (memberId: string | undefined) => {
  const [data, setData] = useState<MemberData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!memberId) return;

    const fetchMemberData = async () => {
      setLoading(true);
      try {
        const response = await apiOrchestrator.get(`/users-person/${memberId}`);
        setData(response.data);
        setError(null);
      } catch (err) {
        setError("Erro ao buscar os dados do membro.");
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchMemberData();
  }, [memberId]);

  return { data, loading, error };
};
