import React from "react";
import { Avatar, Box, Skeleton } from "@mui/material";
import Text from "components/Text";
import { MemberData } from "../hooks/useMemberData";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import { OrganizationMember } from "pages/DigitalGovernance/GovernanceUsers/types/users";
import theme from "styles/theme";

interface HeaderInfoMemberProps {
  memberData: MemberData | null;
  imageOfMember: string | null;
  imageOfWhoInvitedHim: string | null;
  loadingImageOfMember: boolean;
  loadingImageOfWhoInvitedHim: boolean;
  member: OrganizationMember;
  onClickInviteName?: (newMemberId: string) => void;
}

const HeaderInfoMember: React.FC<HeaderInfoMemberProps> = ({
  memberData,
  imageOfMember,
  loadingImageOfMember,
  imageOfWhoInvitedHim,
  loadingImageOfWhoInvitedHim,
  member,
  onClickInviteName,
}) => {
  const hasInviteInfo =
    (member.invited_by?.first_name || member.invited_by?.last_name) &&
    imageOfWhoInvitedHim;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
        minHeight: "6.25rem",
      }}
    >
      <Box
        sx={{
          background: theme.COLORS.BACKGROUND_PRIMARY,
          borderRadius: "0.75rem",
          padding: "0.5rem",
          flex: 0.8,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {loadingImageOfMember ? (
            <Skeleton
              variant="circular"
              width={"5rem"}
              height={"5rem"}
              sx={{
                display: "flex",
                alignSelf: "center",
                justifySelf: "center",
                marginRight: "0.5rem",
              }}
            />
          ) : (
            <Avatar
              src={imageOfMember || undefined}
              alt={`${member.user?.first_name} ${member.user?.last_name}`}
              sx={{
                display: "flex",
                alignSelf: "center",
                justifySelf: "center",
                width: "5rem",
                height: "5rem",
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Text size="small">Nome</Text>
            <Text size="medium">
              {memberData?.first_name} {memberData?.last_name}
            </Text>
          </Box>
          <Box>
            <Text size="small">Email</Text>
            <Text size="medium">{memberData?.email}</Text>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Text size="small">Telefone</Text>
            <Text size="medium">
              {memberData?.whatsapp_number
                ? formatPhoneNumber(memberData.whatsapp_number)
                : "Número não informado"}
            </Text>
          </Box>
          <Box>
            {/* <Text size="small">Último acesso</Text>
            <Text size="medium">Data não informada</Text> */}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: theme.COLORS.BACKGROUND_PRIMARY,
          borderRadius: "0.75rem",
          padding: "0.5rem",
          flex: 0.2,
          gap: "0.5rem",
        }}
      >
        <Text size="small">Convidado para a comunidade por:</Text>
        {loadingImageOfWhoInvitedHim ? (
          <Skeleton
            variant="circular"
            width={"2.8rem"}
            height={"2.8rem"}
            sx={{
              display: "flex",
              alignSelf: "center",
              justifySelf: "center",
              marginRight: "0.5rem",
            }}
          />
        ) : hasInviteInfo ? (
          <>
            <Avatar
              src={imageOfWhoInvitedHim || undefined}
              alt={`${member.invited_by?.first_name} ${member.invited_by?.last_name}`}
              sx={{
                display: "flex",
                alignSelf: "center",
                justifySelf: "center",
                width: "2.8rem",
                height: "2.8rem",
              }}
            />
            <Text
              size="small"
              fontWeight={500}
              textAlign="center"
              underline
              color="white"
              onClick={() =>
                onClickInviteName && member.invited_by?.user_uuid
                  ? onClickInviteName(member.invited_by.user_uuid)
                  : undefined
              }
            >
              {`${member.invited_by?.first_name || ""} ${
                member.invited_by?.last_name || ""
              }`}
            </Text>
          </>
        ) : (
          <Text size="small" fontWeight={500} textAlign="center" color="gray">
            Sem convite
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default HeaderInfoMember;
