import { apiOrchestrator } from "services/apiService";
import { OrganizationMember } from "../types/users";

export const fetchOrganizationMembers = async (): Promise<
  OrganizationMember[]
> => {
  try {
    const response = await apiOrchestrator.get<OrganizationMember[]>(
      "/user_to_organization/members",
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar membros da organização:", error);
    throw new Error("Não foi possível carregar os membros da organização.");
  }
};
