import { useState, useEffect, useRef } from "react";
import { apiRainmakersV2 } from "services/apiService";
import { fetchMeetingRooms } from "utils/meetingUtils";
import { useUser } from "global/UserContext";
import { Event } from "../types/agendaTypes";

export const useEvents = () => {
  const { user } = useUser();
  const organizationId = user?.user_to_organization[0]?.organization?.uuid;
  const userId = user?.id || "";

  const [events, setEvents] = useState<Event[]>([]);
  const [eventsToday, setEventsToday] = useState<Event[]>([]);
  const [eventsFirstSlot, setEventsFirstSlot] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  const fetchEvents = async () => {
    if (!organizationId || !userId) {
      // Wait for IDs to be loaded
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await apiRainmakersV2.get(`/events/v1/events/all`);
      const rawEvents = response.data;

      const meetingRooms = await fetchMeetingRooms(organizationId, userId);

      const currentDate = new Date();
      const currentDay = new Date().toISOString().split("T")[0];
      const currentTime = currentDate.getTime();

      const processedEvents: Event[] = rawEvents.flatMap((event: any) => {
        const slots = event.schedule?.slots || [];

        return slots.map((slot: any) => {
          const eventDate = new Date(slot.init_datetime);
          const eventEndDate = new Date(slot.end_datetime);
          const currentDate = new Date();

          const isOngoing =
            eventDate.toDateString() === currentDate.toDateString() &&
            currentDate >= eventDate &&
            currentDate <= eventEndDate;

          const status = isOngoing
            ? "ongoing"
            : event.participants.total_accepted > 0
              ? "accepted"
              : "pending";

          const duration = calculateDuration(
            slot.init_datetime,
            slot.end_datetime,
          );

          const time = adjustTimeToUTCMinus3(slot.init_datetime);

          const room = meetingRooms.find(
            (room) => room.room_data.id === event.local.room_id,
          );

          return {
            key: `${event.id}-${slot.init_datetime}`,
            id: event.id,
            title: event.name,
            location: event.local.is_online
              ? `Sala: ${room?.room_data.title || "Virtual"}`
              : `Local: ${event.local.location || "Presencial"}`,
            date: eventDate.toLocaleDateString("pt-BR"),
            time,
            participants: {
              participants: event.participants.participants || [],
              total: event.participants.total,
              total_accepted: event.participants.total_accepted,
              total_declined: event.participants.total_declined,
              total_pending: event.participants.total_pending,
            },
            duration,
            status,
            room,
            init_datetime: slot.init_datetime,
            end_datetime: slot.end_datetime,
            initTime: eventDate.getTime(),
            isOngoing,
          };
        });
      });

      const ongoingOrUpcomingEvents = processedEvents.filter((event) => {
        const eventEndTime = new Date(event.end_datetime).getTime();

        return currentTime <= eventEndTime;
      });

      const filteredEventsToday = processedEvents.filter((event) => {
        const eventStartTime = new Date(event.init_datetime).getTime();
        const eventEndTime = new Date(event.end_datetime).getTime();

        return (
          event.init_datetime.split("T")[0] === currentDay &&
          currentTime <= eventEndTime
        );
      });

      setEvents(ongoingOrUpcomingEvents);
      setEventsToday(filteredEventsToday);

      const firstSlotResponse = await apiRainmakersV2.get(
        `/events/v1/events/all?init_datetime=${currentDay}`,
      );
      const rawFirstSlotEvents = firstSlotResponse.data;

      const firstSlotEvents = rawFirstSlotEvents.flatMap((event: any) => {
        const firstSlot = event.schedule?.slots?.[0];
        if (!firstSlot) return []; // Se não houver slots, ignorar o evento

        const eventDate = new Date(firstSlot.init_datetime);
        const eventEndDate = new Date(firstSlot.end_datetime);

        const isOngoing =
          eventDate.toDateString() === currentDate.toDateString() &&
          currentDate >= eventDate &&
          currentDate <= eventEndDate;

        const status = isOngoing
          ? "ongoing"
          : event.participants.total_accepted > 0
            ? "accepted"
            : "pending";

        const duration = calculateDuration(
          firstSlot.init_datetime,
          firstSlot.end_datetime,
        );

        const time = adjustTimeToUTCMinus3(firstSlot.init_datetime);

        const room = meetingRooms.find(
          (room) => room.room_data.id === event.local.room_id,
        );

        return {
          key: `${event.id}-${firstSlot.init_datetime}`,
          id: event.id,
          title: event.name,
          location: event.local.is_online
            ? `Sala: ${room?.room_data.title || "Virtual"}`
            : `Local: ${event.local.location || "Presencial"}`,
          date: eventDate.toLocaleDateString("pt-BR"),
          time,
          participants: {
            participants: event.participants.participants || [],
            total: event.participants.total,
            total_accepted: event.participants.total_accepted,
            total_declined: event.participants.total_declined,
            total_pending: event.participants.total_pending,
          },
          duration,
          status,
          room,
          init_datetime: firstSlot.init_datetime,
          end_datetime: firstSlot.end_datetime,
          initTime: eventDate.getTime(),
          isOngoing,
        };
      });

      const sortedFirstSlotEvents = firstSlotEvents.sort((a: any, b: any) => {
        const dateA = new Date(a.init_datetime).getTime();
        const dateB = new Date(b.init_datetime).getTime();
        return dateA - dateB;
      });

      setEventsFirstSlot(sortedFirstSlotEvents);
    } catch (err) {
      console.error("Failed to fetch events:", err);
      setError("Erro ao buscar eventos. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const adjustTimeToUTCMinus3 = (utcDateTime: string): string => {
    try {
      if (!utcDateTime) {
        throw new Error("Formato de data/hora inválido");
      }

      // Create new date based in ISO 8601
      const date = new Date(utcDateTime);

      if (isNaN(date.getTime())) {
        throw new Error("Data inválida fornecida");
      }

      // Adjustment for UTC-3
      date.setHours(date.getUTCHours() - 3);

      // Return formatted
      return date.toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
    } catch (error) {
      if (error instanceof Error) {
        console.error("Erro ao ajustar o horário para UTC-3:", error.message);
      } else {
        console.error("Erro desconhecido ao ajustar o horário para UTC-3");
      }
      return "Horário Inválido";
    }
  };

  const fetchEventsByDate = async (selectedDate: Date) => {
    setLoading(true);
    setError(null);

    try {
      const formattedDate = selectedDate.toISOString().split("T")[0];
      const response = await apiRainmakersV2.get(
        `/events/v1/events/all?date=${formattedDate}`,
      );

      const rawEvents = response.data;
      const filteredEvents = rawEvents.filter((event: any) => {
        const eventDate = new Date(event.schedule.slots[0]?.init_datetime);
        return eventDate.toDateString() === selectedDate.toDateString();
      });

      setEvents(filteredEvents);
    } catch (err) {
      console.error("Failed to fetch events by date:", err);
      setError("Erro ao buscar eventos para a data selecionada.");
    } finally {
      setLoading(false);
    }
  };

  const calculateDuration = (start: string, end: string) => {
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();
    const totalMinutes = Math.floor((endTime - startTime) / 60000);

    if (totalMinutes < 60) {
      return `${totalMinutes} min`;
    }

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (minutes === 0) {
      return `${hours} hora${hours > 1 ? "s" : ""}`;
    }

    return `${hours} hora${hours > 1 ? "s" : ""} e ${minutes} min`;
  };

  const refreshEvents = async () => {
    if (isRefreshing) {
      console.warn("Refresh already in progress, skipping...");
      return;
    }

    setIsRefreshing(true);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(async () => {
      await fetchEvents();
      setIsRefreshing(false);
    }, 500);
  };

  useEffect(() => {
    if (organizationId && userId) {
      fetchEvents();
    } else {
      setLoading(true);
    }
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [organizationId, userId]);

  return {
    events,
    eventsToday,
    eventsFirstSlot,
    loading,
    error,
    fetchEventsByDate,
    refreshEvents,
  };
};
