import { ReactComponent as IconAnalystAdvisor } from "assets/icons/advisors/icon-analyst-advisor.svg";

export const mapSidebarAccessHub = [
  {
    name: "Mentores",
    type: "advisor",
    title: ``,
    icon: IconAnalystAdvisor,
    filter: false,
    path: "",
    disabled: false,
  },
];
