import React from "react";
import { MenuItem, Select, Checkbox, ListItemText, Box } from "@mui/material";
import Text from "components/Text";
import GlobalModal from "modals/GlobalModal/GlobalModal";
import theme from "styles/theme";

interface FilterModalProps {
  open: boolean;
  onClose: () => void;
  onApply: (filters: Record<string, string[]>) => void;
  permissions: [string, string][];
  plans: [string, string][];
  groups: [string, string][];
  invitedBy: [string, string][];
}

const sharedStyles = {
  menuItem: {
    background: theme.COLORS.BACKGROUND_PRIMARY,
    padding: 0,
    "&.Mui-selected": {
      backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
    },
  },
  checkbox: {
    color: theme.COLORS.PRIMARY,
    "&.Mui-checked": {
      color: theme.COLORS.PRIMARY,
    },
  },
};

const FilterModal: React.FC<FilterModalProps> = ({
  open,
  onClose,
  onApply,
  permissions,
  plans,
  groups,
  invitedBy,
}) => {
  const initialFilters = {
    permission: [] as string[],
    plan: [] as string[],
    group: [] as string[],
    invitedBy: [] as string[],
  };
  const [filters, setFilters] = React.useState(initialFilters);

  const handleFilterChange = (key: string, value: string[]) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleApply = () => {
    onApply(filters);
    onClose();
  };

  const handleCancel = () => {
    setFilters(initialFilters);
    handleApply();
    onClose();
  };

  const isFilterEnabled = Object.values(filters).some(
    (filterArray) => filterArray.length > 0,
  );

  const renderPlaceholder = (
    selected: string[],
    placeholder: string,
    options: [string, string][],
  ) =>
    selected.length === 0 ? (
      <Text>{placeholder}</Text>
    ) : (
      selected
        .map((uuid) => options.find(([id]) => id === uuid)?.[1] || uuid)
        .join(", ")
    );

  return (
    <GlobalModal
      open={open}
      title="FILTRAR"
      description=""
      onCancel={handleCancel}
      onClose={onClose}
      onConfirm={handleApply}
      cancelButtonText="Cancelar"
      confirmButtonText="Filtrar"
      confirmDisabled={!isFilterEnabled}
      width="35rem"
    >
      <Box sx={{ marginBottom: "1rem" }}>
        <Select
          multiple
          fullWidth
          value={filters.permission}
          onChange={(e) =>
            handleFilterChange("permission", e.target.value as string[])
          }
          renderValue={(selected) =>
            renderPlaceholder(selected, "Filtrar por Permissão", permissions)
          }
          displayEmpty
        >
          {permissions.map(([uuid, name]) => (
            <MenuItem key={uuid} value={uuid} sx={sharedStyles.menuItem}>
              <Checkbox
                checked={filters.permission.includes(uuid)}
                sx={sharedStyles.checkbox}
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ marginBottom: "1rem" }}>
        <Select
          multiple
          fullWidth
          value={filters.plan}
          onChange={(e) =>
            handleFilterChange("plan", e.target.value as string[])
          }
          renderValue={(selected) =>
            renderPlaceholder(selected, "Filtrar por Assinatura", plans)
          }
          displayEmpty
        >
          {plans.map(([uuid, name]) => (
            <MenuItem key={uuid} value={uuid} sx={sharedStyles.menuItem}>
              <Checkbox
                checked={filters.plan.includes(uuid)}
                sx={sharedStyles.checkbox}
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ marginBottom: "1rem" }}>
        <Select
          multiple
          fullWidth
          value={filters.group}
          onChange={(e) =>
            handleFilterChange("group", e.target.value as string[])
          }
          renderValue={(selected) =>
            renderPlaceholder(selected, "Filtrar por Grupo Temático", groups)
          }
          displayEmpty
        >
          {groups.map(([uuid, name]) => (
            <MenuItem key={uuid} value={uuid} sx={sharedStyles.menuItem}>
              <Checkbox
                checked={filters.group.includes(uuid)}
                sx={sharedStyles.checkbox}
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <Select
          multiple
          fullWidth
          value={filters.invitedBy}
          onChange={(e) =>
            handleFilterChange("invitedBy", e.target.value as string[])
          }
          renderValue={(selected) =>
            renderPlaceholder(selected, `Filtrar "Convidado por"`, invitedBy)
          }
          displayEmpty
        >
          {invitedBy.map(([uuid, name]) => (
            <MenuItem key={uuid} value={uuid} sx={sharedStyles.menuItem}>
              <Checkbox
                checked={filters.invitedBy.includes(uuid)}
                sx={sharedStyles.checkbox}
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </Box>
    </GlobalModal>
  );
};

export default FilterModal;
