import Spin from "components/Spin";

import * as S from "./styles"

interface YoutubeVideoEmbedProps {
    videoId: string;
    videoLoading: boolean
    onLoad: () => void;
}

export const YoutubeVideoEmbed = ({ videoId, videoLoading, onLoad }: YoutubeVideoEmbedProps) => {
    return (<S.VideoContainer>
        {videoLoading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Spin tip="Loading video..." />
            </div>
        )}
        <iframe
            width="100%"
            height="100%"
            src={`https://youtube.com/embed/${videoId}`}
            title="YouTube video player"
            onLoad={onLoad}
            style={{ display: videoLoading ? "none" : "block" }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    </S.VideoContainer>)

}
