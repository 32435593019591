import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  DialogActions,
  Box,
  Typography,
  Menu,
  MenuItem,
  Divider,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  updateGroupDetails,
  linkUsersToGroup,
  unlinkUserFromGroup,
  fetchOrganizationMembers,
} from "../services/api";
import Text from "components/Text";
import Button from "components/Button";
import InternalButton from "components/InternalButton";
import { useToast } from "hooks/useToast";
import { useUser } from "global/UserContext";
import theme from "styles/theme";
import * as S from "../styles";

interface ViewGroupModalProps {
  open: boolean;
  onClose: () => void;
  groupId: string;
  groupName: string;
  initialData: any;
  onUpdate: () => void;
}

const ViewGroupModal: React.FC<ViewGroupModalProps> = ({
  open,
  onClose,
  groupId,
  groupName,
  initialData,
  onUpdate,
}) => {
  const [groupDetails, setGroupDetails] = useState(initialData);
  const [name, setName] = useState(initialData.name);
  const [description, setDescription] = useState(initialData.description);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [filter, setFilter] = useState("");
  const [filteredAvailableUsers, setFilteredAvailableUsers] = useState<any[]>(
    [],
  );
  const [linkingUsers, setLinkingUsers] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [confirmUnlinkOpen, setConfirmUnlinkOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [refreshMembers, setRefreshMembers] = useState(false);
  const [usersFetched, setUsersFetched] = useState(false);
  const { toastSuccess, toastError, toastInfo } = useToast();
  const { user } = useUser();

  const [organizationMembers, setOrganizationMembers] = useState<any[]>([]);
  const [availableUsers, setAvailableUsers] = useState<any[]>([]);

  const organization_uuid = user?.user_to_organization[0]?.organization?.uuid;

  useEffect(() => {
    setGroupDetails(initialData);
  });

  const triggerRefresh = () => setRefreshMembers((prev) => !prev);

  const fetchAvailableUsers = async () => {
    setGroupDetails(initialData);
    try {
      if (usersFetched) return;

      const response = await fetchOrganizationMembers();
      const allMembers = response.data;

      const currentGroupMemberIds = groupDetails?.members.map(
        (member: any) => member.uuid,
      );

      const filteredUsers = allMembers.filter(
        (member: any) => !currentGroupMemberIds.includes(member.user?.id),
      );

      setOrganizationMembers(allMembers);
      setAvailableUsers(filteredUsers);
      setUsersFetched(true);
      setRefreshMembers(false);
    } catch (error) {
      console.error("Erro ao buscar membros da organização:", error);
      toastError("Erro ao carregar usuários disponíveis.");
    }
  };

  useEffect(() => {
    if (open && linkingUsers) {
      fetchAvailableUsers();
    }
  }, [
    open,
    linkingUsers,
    groupDetails?.members,
    usersFetched,
    toastError,
    refreshMembers,
  ]);

  useEffect(() => {
    if (!open) {
      setUsersFetched(false);
    }
  }, [open]);

  const handleUpdateGroup = async () => {
    if (!organization_uuid) {
      toastError("Organização não encontrada.");
      return;
    }

    try {
      await updateGroupDetails(groupId, {
        name,
        description,
        organization_uuid,
      });

      toastSuccess("Detalhes do grupo atualizados com sucesso!");
      setIsEditingName(false);
      setIsEditingDescription(false);
      onUpdate();
      setUsersFetched(false);
      triggerRefresh();
    } catch (error) {
      console.error("Erro ao atualizar detalhes do grupo:", error);
      toastError("Erro ao atualizar detalhes do grupo.");
    }
  };

  const handleUnlinkUser = async () => {
    if (!selectedUser) return;

    try {
      await unlinkUserFromGroup(selectedUser.user_to_team_uuid);

      toastSuccess(
        `Usuário ${selectedUser.first_name} desvinculado com sucesso!`,
      );
      setConfirmUnlinkOpen(false);
      setSelectedUser(null);
      onUpdate();
      setUsersFetched(false);
      triggerRefresh();
    } catch (error) {
      console.error("Erro ao desvincular usuário:", error);
      toastError("Erro ao desvincular usuário.");
    }
  };

  const handleLinkUsers = async () => {
    try {
      const userParams = selectedUsers.map((userId) => {
        const user = availableUsers.find(
          (member: any) => member.user.id === userId,
        );

        return {
          user_uuid: userId,
          team_position: /* user?.group_role?.name || */ "Membro",
        };
      });

      if (userParams.length === 0) {
        toastError("Nenhum usuário selecionado.");
        return;
      }

      await linkUsersToGroup(groupId, userParams);
      toastSuccess("Usuários vinculados com sucesso!");
      setSelectedUsers([]);
      setLinkingUsers(false);
      onUpdate();
      setUsersFetched(false);
      triggerRefresh();
      fetchAvailableUsers();
    } catch (error) {
      console.error("Erro ao vincular usuários:", error);
      toastError("Erro ao vincular usuários.");
    }
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, user: any) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedUser(user);
    setSelectedUserId(user.uuid);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleOpenConfirmUnlink = () => {
    setConfirmUnlinkOpen(true);
    handleCloseMenu();
  };

  const handleCloseConfirmUnlink = () => {
    setConfirmUnlinkOpen(false);
  };

  const handleViewMember = () => {
    toastInfo(`Visualizar informações do membro: ${selectedUser.first_name}`);
    handleCloseMenu();
  };

  const toggleUserSelection = (userId: string) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId],
    );
  };

  const filteredUsers = groupDetails?.members.filter((user: any) =>
    `${user.first_name} ${user.last_name}`
      .toLowerCase()
      .includes(filter.toLowerCase()),
  );

  useEffect(() => {
    if (filter.trim() === "") {
      setFilteredAvailableUsers(availableUsers);
    } else {
      const filtered = availableUsers.filter((userItem: any) => {
        const fullName =
          `${userItem.user?.first_name || ""} ${userItem.user?.last_name || ""}`.toLowerCase();
        return fullName.includes(filter.toLowerCase());
      });

      setFilteredAvailableUsers(filtered);
    }
  }, [filter, availableUsers]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          background: theme.COLORS.BACKGROUND_SECONDARY,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          minHeight: "40rem",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          color: theme.COLORS.TEXT_PRIMARY,
          fontSize: 18,
        }}
      >
        GRUPO TEMÁTICO
      </DialogTitle>

      <Divider />

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: "8px",
            borderRadius: "8px",
            backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="standard"
              fullWidth
              disabled={!isEditingName}
              size="small"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {isEditingName ? (
              <IconButton
                onClick={handleUpdateGroup}
                sx={{ p: 0.5, color: theme.COLORS.PRIMARY }}
              >
                <SaveOutlinedIcon sx={{ fontSize: "1.25rem" }} />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => setIsEditingName(true)}
                sx={{
                  p: 0.5,
                  color: theme.COLORS.PRIMARY,
                }}
              >
                <EditOutlinedIcon sx={{ fontSize: "1.25rem" }} />
              </IconButton>
            )}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Descrição"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="standard"
              fullWidth
              multiline
              disabled={!isEditingDescription}
              size="small"
              sx={{ border: "none" }}
              InputProps={{
                disableUnderline: true,
              }}
            />
            {isEditingDescription ? (
              <IconButton
                onClick={handleUpdateGroup}
                sx={{ p: 0.5, color: theme.COLORS.PRIMARY }}
              >
                <SaveOutlinedIcon sx={{ fontSize: "1.25rem" }} />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => setIsEditingDescription(true)}
                sx={{ p: 0.5, color: theme.COLORS.PRIMARY }}
              >
                <EditOutlinedIcon sx={{ fontSize: "1.25rem" }} />
              </IconButton>
            )}
          </Box>
        </Box>
        {!linkingUsers ? (
          <>
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text size="medium">
                  Usuários Vinculados ({groupDetails.members.length})
                </Text>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <TextField
                    placeholder="Buscar por Usuário"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    size="small"
                    sx={S.SearchService}
                  />

                  <InternalButton
                    variant="outline"
                    color="primary"
                    onClick={() => setLinkingUsers(true)}
                    size="large"
                    background={theme.COLORS.PRIMARY}
                    iconPosition="left"
                    textColor={theme.COLORS.TEXT_PRIMARY}
                    icon={<AddIcon sx={{ fontSize: "auto" }} />}
                  >
                    Vincular usuário existente
                  </InternalButton>
                </Box>
              </Box>
              <Box
                sx={{
                  maxHeight: 220,
                  overflowY: "auto",
                  borderRadius: 1,
                  backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
                }}
              >
                {filteredUsers.length > 0 ? (
                  <List
                    sx={{ backgroundColor: theme.COLORS.BACKGROUND_SECONDARY }}
                  >
                    {filteredUsers.map((user: any) => (
                      <ListItem
                        key={user.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
                          marginBottom: "0.5rem",
                          borderRadius: "8px",
                          padding: 0,
                          paddingLeft: "2rem",
                        }}
                      >
                        <ListItemText
                          primary={`${user.first_name} ${user.last_name}`}
                          secondary={user.position || ""}
                        />
                        <ListItemSecondaryAction>
                          <IconButton onClick={(e) => handleOpenMenu(e, user)}>
                            <MoreHorizIcon
                              sx={{ color: theme.COLORS.PRIMARY }}
                            />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Text color="gray" textAlign="center">
                    Nenhum usuário encontrado.
                  </Text>
                )}
              </Box>

              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleCloseMenu}
                sx={{
                  "& .MuiPaper-root": {
                    background: theme.COLORS.BACKGROUND_PRIMARY,
                    color: theme.COLORS.TEXT_PRIMARY,
                    borderRadius: "8px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    padding: "2px",
                  },
                  "& .MuiMenuItem-root": {
                    fontSize: "0.75rem",
                    padding: "2px 4px",
                    "&:hover": {
                      backgroundColor: theme.COLORS.PRIMARY,
                      color: theme.COLORS.BLACK,
                      borderRadius: "4px",
                    },
                  },
                }}
              >
                <MenuItem onClick={handleOpenConfirmUnlink}>
                  Desvincular membro
                </MenuItem>
                <MenuItem onClick={handleViewMember}>
                  Visualizar membro
                </MenuItem>
              </Menu>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 2,
              backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "4px",
              }}
            >
              <Text size="medium">
                Usuários Selecionados ({selectedUsers.length})
              </Text>

              <TextField
                placeholder="Buscar por Usuário"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                size="small"
                sx={S.SearchService}
              />
            </Box>

            <Box
              sx={{
                maxHeight: "220px",
                overflowY: "auto",
                backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
                borderRadius: "8px",
              }}
            >
              {filteredAvailableUsers.length > 0 && usersFetched ? (
                <List>
                  {filteredAvailableUsers.map((userItem: any) => {
                    if (!userItem?.user?.id) return null;

                    return (
                      <ListItem
                        key={userItem.user.uuid}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "0",
                          paddingLeft: "1.5rem",
                          backgroundColor: theme.COLORS.BACKGROUND_PRIMARY,
                          borderRadius: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <Checkbox
                            edge="start"
                            checked={selectedUsers.includes(userItem.user.id)}
                            onChange={() =>
                              toggleUserSelection(userItem.user.id)
                            }
                            sx={{
                              color: theme.COLORS.PRIMARY,
                              "&.Mui-checked": {
                                color: theme.COLORS.PRIMARY,
                              },
                            }}
                          />
                          <ListItemText
                            primary={`${userItem.user?.first_name || ""} ${userItem.user?.last_name || ""}`}
                            secondary={userItem.group_role?.name || ""}
                            sx={{ color: theme.COLORS.TEXT_SECONDARY }}
                          />
                        </Box>
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                <Text color="gray" textAlign="center">
                  Nenhum usuário disponível para adicionar.
                </Text>
              )}
            </Box>

            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "5rem",
              }}
            >
              <Button
                variant="outline"
                color="primary"
                fullWidth
                onClick={() => setLinkingUsers(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="primary"
                color="primary"
                onClick={handleLinkUsers}
                disabled={selectedUsers.length === 0}
                fullWidth
              >
                Salvar
              </Button>
            </DialogActions>
          </Box>
        )}
      </DialogContent>

      {!linkingUsers && (
        <DialogActions
          sx={{
            display: "flex",
            alignSelf: "center",
            width: "200px",
          }}
        >
          <Button onClick={onClose} variant="outline" fullWidth>
            Fechar
          </Button>
        </DialogActions>
      )}

      <Dialog
        open={confirmUnlinkOpen}
        onClose={handleCloseConfirmUnlink}
        PaperProps={{
          sx: {
            background: theme.COLORS.BACKGROUND_SECONDARY,
            borderRadius: "8px",
            maxWidth: "400px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: theme.COLORS.TEXT_PRIMARY,
            fontSize: 18,
          }}
        >
          CONFIRMAÇÃO
        </DialogTitle>

        <Divider />

        <DialogContent>
          <Typography
            sx={{
              color: theme.COLORS.TEXT_PRIMARY,
              textAlign: "start",
              mb: 2,
              fontSize: "1rem",
            }}
          >
            Deseja realmente desvincular o usuário{" "}
            <Typography component="span" fontWeight="bold">
              {selectedUser?.first_name} {selectedUser?.last_name}
            </Typography>{" "}
            do grupo temático{" "}
            <Typography component="span" fontWeight="bold">
              {groupName}
            </Typography>
            ?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleCloseConfirmUnlink}
            variant="outline"
            fullWidth
          >
            Cancelar
          </Button>
          <Button onClick={handleUnlinkUser} variant="primary" fullWidth>
            Desvincular usuário
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default ViewGroupModal;
